@import '../../scss/common';

.article-page-hero {
  background-color: var(--wipfli-core-black);
  padding: toRem(102) 0 toRem(56);

  &__container {
    @include break-lg {
      @include container-width;
    }

    @include break-xl {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__inner-contain {
    position: relative;
    z-index: 0;

    @include break-md {
      padding-left: toRem(15);
      padding-right: toRem(15);
    }

    @include break-xl {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__content {
    max-width: toRem(730);
    @include break-md {
      min-height: toRem(404);
    }
  }

  &__pretitle {
    font-family: var(--title-font-family);
    font-size: toRem(24);
    display: inline-block;
    line-height: 1.5;
    text-transform: uppercase;

    &:any-link {
      text-decoration: none;
    }

    @include break-md {
      font-size: toRem(34);
      line-height: 1.06;
    }

    &-contain {
      padding: 0 toRem(20) toRem(5);

      @include break-md {
        padding: 0 0 toRem(23);
      }
    }
  }

  &__title {
    color: var(--wipfli-white);
    font-size: toRem(50);
    line-height: 1;
    letter-spacing: -0.31px;
    margin-bottom: 0;
    padding: 0 toRem(20);
    position: relative;
    text-transform: uppercase;

    @include break-md {
      font-size: toRem(70);
      letter-spacing: normal;
      margin-bottom: toRem(12);
      padding: 0;
    }
  }

  &__description {
    margin-top: toRem(24);
    padding: 0 toRem(20);
    max-width: toRem(540);

    &,
    * {
      color: var(--wipfli-white);
      font-size: toRem(14);
      font-weight: 500;
      line-height: 1.57;
      strong,
      b {
        font-weight: bold;
      }
      @include break-md {
        font-size: toRem(22);
        line-height: 1.36;
      }
    }

    @include break-md {
      margin-top: toRem(35);
      padding: 0;
    }
  }

  &__mobile-image {
    margin-top: toRem(-25);

    img {
      height: 100%;
      max-height: 100%;
      object-fit: cover;
      width: 100%;
    }

    @include break-md {
      display: none;
    }
  }

  &__desktop-image {
    display: none;

    @include break-md {
      position: absolute;
      display: block;
      top: 0;
      right: 0;
      max-width: toRem(539);
      height: toRem(404);
      z-index: -1;
    }

    img {
      height: 100%;
      max-height: 100%;
      object-fit: cover;
      width: toRem(539);
    }
  }

  &__link,
  &__btn {
    border: 1.8px solid var(--wipfli-white);
    border-color: var(--wipfli-blue);
    padding: 11px 20px 10px;
    position: relative;
    color: var(--wipfli-white);
    font-size: 14px;
    font-weight: 600;
    display: flex;
    width: fit-content;
    background: linear-gradient(to left, transparent 50%, var(--wipfli-blue) 50%) right;
    background-size: 200%;
    transition: 0.25s ease-out;

    &:any-link {
      color: var(--wipfli-white);
      text-decoration: none;
    }

    &:hover {
      background-position: left;
      border-bottom: 1.8px solid var(--wipfli-blue);
      border-color: var(--wipfli-blue);
      color: var(--wipfli-white);
      text-decoration: none;
    }

    @include break-md {
      font-size: 18px;
      padding: 14px 26px;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    padding: 11px 27px 10px 29px;

    svg {
      margin-left: toRem(10);
    }

    &-contain {
      padding: 0 toRem(20);
      margin-top: toRem(12);

      @include break-md {
        padding: 0;
        margin-top: toRem(24);
      }
    }
  }

  &__share {
    &-contain {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }

    &-list {
      list-style: none;
      margin: 0;
      padding: 0;

      &-item {
        display: block;
        margin-bottom: toRem(5);

        &-link {
          position: relative;
          font-weight: 500;
          padding-left: toRem(26);

          &-facebook,
          &-twitter,
          &-linkdin,
          &-mail {
            &::before {
              content: '';
              background-repeat: no-repeat;
              background-size: contain;
              position: absolute;
              left: 0;
              height: toRem(15);
              width: toRem(15);
              top: toRem(2);
            }
          }

          &-facebook {
            &::before {
              background-image: url('../../../public/local/Home/Project/demo-site/demo-site/facebook-plum.svg');
            }
          }

          &-twitter {
            &::before {
              background-image: url('../../../public/local/Home/Project/demo-site/demo-site/twitter-plum.svg');
              top: toRem(3);
            }
          }

          &-linkdin {
            &::before {
              background-image: url('../../../public/local/Home/Project/demo-site/demo-site/linkedin-plum.svg');
              top: toRem(1);
            }
          }

          &-mail {
            &::before {
              background-image: url('../../../public/local/Home/Project/demo-site/demo-site/mail-icon.svg');
              top: toRem(3);
            }
          }

          &:any-link {
            text-decoration: none;

            &:hover {
              border-bottom: none;

              span {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    &-btn {
      background-color: transparent;
      border: none;

      &-content {
        position: relative;
        margin-right: toRem(5);

        &:hover {
          .article-page-hero__share__inner-contain {
            visibility: visible;
            opacity: 1;
          }
        }

        @include break-md {
          margin-right: toRem(20);
        }
      }

      svg {
        height: toRem(17);
        width: toRem(17);

        @include break-md {
          height: toRem(24);
          width: toRem(24);
        }
      }
    }

    &__inner-contain {
      position: absolute;
      background-color: #fff;
      z-index: 2;
      top: 100%;
      right: 0;
      border: 1px solid var(--wipfli-blue);
      padding: 8px;
      visibility: hidden;
      width: toRem(140);
      opacity: 0;
      transition: visibility 0.3s, opacity 0.3s ease-in-out;
    }
  }

  &__print-btn {
    display: none;
    background-color: transparent;
    border: none;
    position: relative;
    svg {
      height: toRem(17);
      width: toRem(17);

      @include break-md {
        height: toRem(24);
        width: toRem(24);
      }
    }
    @include break-md {
      display: block;
    }
  }

  &__inner-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: toRem(21);
    padding: 0 toRem(20);

    @include break-md {
      padding: 0 toRem(15);
      margin-top: toRem(81);
    }
    @include break-xl {
      padding: 0;
    }
  }
  @include break-md {
    .mt-set {
      margin-top: toRem(-90);
    }
  }
  &__publish-date {
    small {
      color: var(--wipfli-white);
      font-size: toRem(12);
      line-height: 1.67;

      @include break-md {
        font-size: toRem(15);
      }
    }
  }

  @include break-md {
    padding: toRem(168) 0 toRem(53);
  }
}
