@import '../../scss/common';

.animated-statistics {
  background-color: var(--wipfli-blue);
  &-container {
    width: 100%;
    max-width: 1110px;
    margin: 0 auto;
    padding: 54px 34px 60px 36px;
    @include break-md {
      padding: 70px 20px 82px 20px;
    }
    @include break-xl {
      padding-left: 0;
      padding-right: 0;
    }
    &__whitebackground {
      background-color: var(--wipfli-white);

      .animated-statistics__title {
        color: var(--wipfli-blue);
      }
      .animated-statistics__items-lists {
        width: 50%;
      }
      .animated-statistics__items-lists-content-number {
        color: var(--wipfli-blue);
        &.animation {
          color: var(--wipfli-blue);
          animation: fadeIn 1s ease-in forwards;
        }
      }
      .animated-statistics__items-lists-content-symbol {
        color: var(--wipfli-blue);
        display: block;
        &.animation {
          color: var(--wipfli-blue);
          animation: fadeIn 1s ease-in forwards;
        }
      }
      .animated-statistics__items-lists-content-prefix {
        color: var(--wipfli-blue);
        display: block;
        &.animation {
          color: var(--wipfli-blue);
          animation: fadeIn 1s ease-in forwards;
        }
      }
      .animated-statistics__items-lists-content-desc {
        color: var(--wipfli-blue);
        &.animation {
          color: var(--wipfli-blue);
          animation: fadeIn 1s ease-in forwards;
        }
      }
    }
    &__bluebackground {
      .animated-statistics__items-lists-content-number {
        color: var(--wipfli-white);
        mix-blend-mode: initial;
      }
      .animated-statistics__items-lists-content-symbol {
        display: block;
        color: var(--wipfli-white);
      }
      .animated-statistics__items-lists-content-desc {
        color: var(--wipfli-white);
        mix-blend-mode: initial;
      }
    }
  }
  &__title {
    text-align: center;
    color: var(--wipfli-white);
    font-style: italic;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: normal;
    @include break-md {
      margin-bottom: 21px;
      font-size: 34px;
      line-height: 1.35;
    }
  }
  &__items {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    @include break-lg {
      margin-left: -35px;
      margin-right: -35px;
    }
    &-lists {
      width: 50%;
      padding-bottom: 15px;
      @include break-lg {
        width: 25%;
        padding-bottom: 0px;
        padding-left: 35px;
        padding-right: 35px;
      }
      &-content {
        display: flex;
        justify-content: center;
        &-number {
          color: var(--wipfli-blue);
          mix-blend-mode: multiply;
          font-family: var(--title-font-family);
          text-align: center;
          font-size: 38px;
          letter-spacing: -1px;
          @include break-md {
            font-size: 70px;
            font-weight: normal;
            line-height: 1.32;
          }
          &.animation {
            color: var(--wipfli-white);
            mix-blend-mode: unset;
            animation: fadeIn 1s ease-in forwards;
          }
        }
        &-symbol {
          color: var(--wipfli-white);
          font-family: var(--title-font-family);
          font-size: 38px;
          font-weight: normal;
          display: none;
          @include break-md {
            font-size: 70px;
            line-height: 1.32;
          }
          &.show {
            display: block;
            animation: fadeIn 1s ease-in forwards;
          }
        }
        &-prefix {
          color: var(--wipfli-white);
          font-family: var(--title-font-family);
          font-size: 38px;
          font-weight: normal;
          display: none;
          @include break-md {
            font-size: 70px;
            line-height: 1.32;
          }
          &.show {
            display: block;
            animation: fadeIn 1s ease-in forwards;
          }
        }
        &-desc {
          color: var(--wipfli-blue);
          mix-blend-mode: multiply;
          margin: 0 auto;
          font-weight: 500;
          line-height: 1.36;
          letter-spacing: normal;
          text-align: center;
          @include break-md {
            font-size: 20px;
          }
          &.animation {
            color: var(--wipfli-white);
            mix-blend-mode: unset;
            animation: fadeIn ease-in 1s forwards;
          }
        }
      }
    }
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
