@import '../../scss/common';

.server-error {
  //************************************************
  // mobile first
 
  //************************************************
  // tablet overrides
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  h1{
    color: var(--wipfli-blue);
    @include break-md{
      font-size: 110px;
    }
  }
  p{
    color: var(--wipfli-core-black);
    font-weight: 700;
    @include break-md{
      font-size: 30px;
    }
  }
  @include break-md {
    
  }

  //************************************************
  // desktop overrides
  @include break-lg {

  }
}