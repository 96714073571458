:root {
  //color palette
  --wipfli-black: rgba(0, 0, 0, 0.9);
  --wipfli-white: #fff;
  --wipfli-blue: #0050ff;
  --wipfli-dark-gray: #63666a;
  --wipfli-light-gray: #d0d3d4;
  --wipfli-dark-blue: #151f6d;
  --wipfli-cyan: #41b6e6;
  --wipfli-muted-blue: #abcae9;
  --wipfli-blue-75: #1d64ff;
  --wipfli-blue-50: #80a7ff;
  --wipfli-blue-25: #bfd3ff;
  --wipfli-core-black: black;
  --wipfli-gray: #d0d3d4;
  --wipfli-suva-gray: #8a8a8a;
  --form-label-clr: #63666a;
  --form-border-color: var(--wipfli-blue);
  --form-focus-label-clr: var(--wipfli-white);
  --form-active-label-clr: #8a8a8a;
  --form-input-focus-bg-clr: var(--wipfli-blue);
  --form-input-focus-clr: var(--wipfli-white);
  --form-input-clr: #63666a;
  --form-error-clr: #ff0000;
  --form-error-border-clr: #ff0000;
  --menu-bg-color: var(--wipfli-white);
  --menu-color: var(--wipfli-blue);
  --menu-bg-hover-color: var(--wipfli-blue);
  --menu-hover-color: var(--wipfli-white);
  --multi-value-bg-color: var(--wipfli-blue);
  --multi-value-color: #fff;
  --multi-value-remove-bg-color: var(--wipfli-white);
  --multi-value-remove-hover-bg-color: var(--wipfli-blue);
  --multiselect-color: var(--wipfli-blue);
  //spacing
  --wipfli-1x: 15px;
  --wipfli-2x: 30px;
  --wipfli-3x: 60px;
  --wipfli-4x: 90px;

  // font-family
  --title-font-family: 'Anton', sans-serif;
  --primary-font-family: 'Montserrat', sans-serif;
  --secondary-font-family: 'Lora', serif;
}
