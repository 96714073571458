@import './common';

:root {
  font-size: 16px;
  scroll-behavior: auto;
}

h1,
.heading1 {
  font-size: 3.125rem; //50px
  line-height: 1;
  @include break-lg {
  font-size: 6.875rem; // 110px
  line-height: 6.875rem; // 110px
  }
}

h2,
.heading2 {
  font-size: 3.125rem; // 50px
  line-height: 3.125rem; // 50px

  @include break-lg {
    font-size: 4.375rem; // 70px
    line-height: 4.375rem; // 70px
  }
}

h3,
.heading3 {
  color: #0050ff;
  font-size: 2.125rem; // 34px
  line-height: 2.25rem; // 36px

  @include break-lg {
    font-size: 3rem; // 48px
    line-height: 3.125rem; // 50px 
  }
}

h4,
.heading4 { 
  font-size: 1.5rem; // 24px
  line-height: 2.25rem; // 36px
  
  @include break-lg {
    font-size: 2.125rem; // 34px
    line-height: 2.25rem; // 36px
  }
}

h5,
.heading5 {
  font-size: 1.25rem; // 20px
  line-height: 2.875rem; // 46px

  @include break-lg {
    font-size: 2.125rem; // 34px
    line-height: 2.4rem; // 46px
  }
}

p {
  font-size: 0.875rem; // 14px
  line-height: 1.375rem; // 22px

  @include break-lg {
    font-size: 1.125rem; // 18px
    line-height: 1.625rem; // 26px
  }
}

a:any-link {
  color: var(--wipfli-blue);
  text-decoration: underline;
  text-underline-offset: 1px;
  text-decoration-thickness: 1px;
}

a:hover {
  border-bottom: 1.5px solid var(--wipfli-blue);
}

small {
  font-size: 0.75rem; // 12px
  letter-spacing: 0.013rem; //0.2px
  line-height: 1.2rem; // 19.2px
}

blockquote {
  font-size: 1.25rem; // 20px
  line-height: 1.75rem; // 28px
  
  @include break-lg {
    font-size: 2.125rem; // 34px
    line-height: 2.4rem; // 46px
  }
  & + figcaption {
    color: var(--wipfli-blue);
    font-weight: fw(bold);
  }
}

figcaption {
  color: #63666a;
  font-size: 0.75rem; // 12px
  line-height: 1.67; 

  @include break-lg {
    font-size: 0.87rem; // 14px
    line-height: 1.47; 
  }

  cite {
    font-weight: fw(regular);
  }
}