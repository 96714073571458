@import '../../scss/common';

.two-column-form {
  background-color: var(--wipfli-blue);
  padding: toRem(53) toRem(20) toRem(63);

  &__container {
    @include container-width;

    @include break-md {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__pretitle {
    color: var(--wipfli-core-black);
    font-family: var(--title-font-family);
    font-size: toRem(24);
    display: inline-block;
    line-height: 1.5;
    text-transform: uppercase;
    text-decoration: none;
    margin-bottom: 0;
    &:any-link {
      color: var(--wipfli-core-black);
    }

    &-contain {
      margin-bottom: toRem(19);
    }

    @include break-md {
      font-size: toRem(34);
      line-height: 1.06;
    }
  }

  &__title {
    color: var(--wipfli-white);
    font-size: toRem(50);
    letter-spacing: -0.31px;
    line-height: 1;
    margin-bottom: toRem(47);
    text-transform: uppercase;

    @include break-md {
      font-size: toRem(110);
      letter-spacing: normal;
      margin-bottom: toRem(34);
    }
  }

  &__description {
    margin-bottom: toRem(18);

    &,
    * {
      color: var(--wipfli-white);
      font-size: toRem(14);
      line-height: 1.57;

      @include break-md {
        font-size: toRem(18);
        line-height: 1.44;
      }
    }
  }

  &__column {
    &-left,
    &-right {
      @include break-md {
        width: 50%;
      }
    }

    &-left {
      @include break-md {
        padding-right: toRem(70);
      }

      @include break-lg {
        padding-right: toRem(114);
      }
    }
  }

  form:not(#scPageExtendersForm) {
    --form-label-clr: #80a7ff;
    --form-border-color: var(--wipfli-white);
    --form-focus-label-clr: #1d64ff;
    --form-active-label-clr: #80a7ff;
    --form-input-focus-bg-clr: var(--wipfli-white);
    --form-input-focus-clr: #0050ff;
    --form-input-clr: var(--wipfli-white);
    --form-error-clr: var(--wipfli-white);
    --form-error-border-clr: #80a7ff;
    --menu-bg-color: var(--wipfli-blue);
    --menu-color: var(--wipfli-white);
    --menu-bg-hover-color: var(--wipfli-white);
    --menu-hover-color: var(--wipfli-blue);
    --multi-value-bg-color: var(--wipfli-white);
    --multi-value-color: #000;
    --multi-value-remove-bg-color: var(--wipfli-blue);
    --multi-value-remove-hover-bg-color: var(--wipfli-white);
    --multiselect-color: var(--wipfli-white);
    button {
      border: 1.8px solid var(--wipfli-white);
      background: linear-gradient(to left, var(--wipfli-blue) 50%, var(--wipfli-white) 50%) right;
      border: 1.8px solid var(--wipfli-white);
      color: var(--wipfli-white);
      background-size: 200%;
      &::after {
        background-image: url('../../../public/local/Home/Project/demo-site/demo-site/white.svg');
        background-repeat: no-repeat;
        background-size: contain;
      }

      &:hover {
        border-bottom: 1.8px solid var(--wipfli-white);
        color: var(--wipfli-blue);
        background-position: left;
        &::after {
          background-image: url('../../../public/local/Home/Project/demo-site/demo-site/blue.svg');
          background-repeat: no-repeat;
          background-size: contain;
        }
      }
    }
  }

  @include break-md {
    padding: toRem(87) toRem(15) toRem(86);
  }

  @include break-xl {
    padding: toRem(87) 0 toRem(86);
  }
}
