@import '../../scss/common';

.statistics {
  //************************************************
  // mobile first
  background-color: var(--wipfli-black);
  color: var(--wipfli-white);
  position: relative;

  img {
    display: none;
  }

  ul {
    list-style-type: none;
    width: 100%;
    max-width: 1110px;
    margin: 0 auto;
    padding: 94px 27px;    
    display: flex;
    flex-direction: column;
    gap:10px;
    
    li {
      display: flex;
      align-items: center;
      font-family: var(--title-font-family);
      gap: 11px;
      text-transform: uppercase;
      opacity: 0.5;
      transition: opacity 0.3s ease-in-out;
      
      .statistics__details {
        max-width: 250px;
      }

      @include break-md {
        gap: 18px;
      }
    }
    li.active {
      opacity: 1;
    }
  }

  &__value {
    display: inline-block;
    font-size: 50px;
    @include break-md {
      font-size: 110px;
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      display: block;
      font-size: 20.2px;
      line-height: 21px;
      @include break-md {
        font-size: 28.8px;
        line-height: 30px;
      }

      &.symbol {
        font-size: 40px;
        line-height: 30px;
        @include break-md {
          margin-bottom: 10px;
          font-size: 70px;
          line-height: 40px;
          margin-bottom: 13px;
        }
      }
    }
  }
  //************************************************
  // tablet overrides
  @include break-md {
    ul {
      padding: 94px 20px;
    }
  }

  //************************************************
  // desktop overrides
  @include break-xl {
    img {
      display: block;
      width: 100%;
      height: auto;
    }

    ul {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0;
      gap:0;

      justify-content: center;
      margin-top: auto;
      margin-bottom: auto;
      z-index: 5;
    }
  }
}
