@import '../../scss/common';

.article-page {
  padding: toRem(12) toRem(20) 0;

  &__container {
    max-width: toRem(730);
    margin: 0 auto;

    button {
      align-items: center;
      display: flex;
      background-color: transparent;
      border: 1.4px solid var(--wipfli-white);
      color: var(--wipfli-white);
      font-size: toRem(14);
      font-weight: 600;
      line-height: normal;
      padding: toRem(14) toRem(20);
      transition: background-color 0.3s ease-in-out;

      &:hover {
        background-color: var(--wipfli-blue);
      }

      @include break-md {
        font-size: toRem(18);
      }
    }
  }

  @include break-sm {
    padding: toRem(12) toRem(15) 0;
  }

  @include break-md {
    padding: toRem(12) 0 0;
  }
}
