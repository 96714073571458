@import '../../scss/common';

.hero {
  background-color: var(--wipfli-core-black);

  &__container {
    padding: toRem(107) toRem(20) toRem(54);

    @include break-md {
      padding: toRem(169) toRem(15) toRem(91);
    }

    @include break-lg {
      @include container-width;
    }

    @include break-xl {
      padding-left: 0;
      padding-right: 0;
    }
    @media (min-width: 1920px) {
      position: relative;
    }
  }

  &__pretitle {
    color: var(--wipfli-blue);
    font-family: var(--title-font-family);
    font-size: toRem(24);
    line-height: 1.08;
    margin-bottom: toRem(10);
    text-transform: uppercase;
    display: block;
    position: relative;
    z-index: 1;

    &:any-link {
      text-decoration: none;
    }

    &:hover {
      border-bottom: none;
      text-decoration: none;
    }

    @include break-md {
      font-size: toRem(34);
      line-height: 1.06;
      margin-bottom: toRem(22);
    }
  }

  &__title {
    color: var(--wipfli-white);
    margin-bottom: toRem(118);
    max-width: toRem(248);
    text-transform: uppercase;

    @include break-sm {
      max-width: toRem(450);
      margin-bottom: toRem(47);
    }

    @include break-md {
      max-width: toRem(517);
    }
    
  }

  &__description {
    &,
    * {
      color: var(--wipfli-white);
      font-size: toRem(14);
      line-height: 1.57;

      @include break-md {
        font-size: toRem(18);
        line-height: 1.44;
      }
    }

    @include break-sm {
      max-width: toRem(450);
    }

    @include break-md {
      max-width: toRem(545);
      animation: fadeInHeroImage 5s;
    }
    @media (min-width: 1920px) {
      max-width: toRem(480);
    }
  }

  &__inner-contain {
    position: relative;
    z-index: 0;
  }

  &__link {
    margin-top: 0.875rem;
    .button {
      border-color: var(--wipfli-blue);
    }
    @include break-md {
      margin-top: 1.9375rem;
    }
  }

  &__play-btn {
    align-items: center;
    display: flex;
    background: linear-gradient(to left, transparent 50%, var(--wipfli-blue) 28%) right;
    background-size: 200%;
    border: 1.4px solid var(--wipfli-white);
    color: var(--wipfli-white);
    font-size: toRem(14);
    font-weight: 600;
    line-height: normal;
    padding: toRem(14) toRem(20);
    transition: all 0.3s ease-in-out;

    svg {
      margin-left: toRem(12);
    }

    &:hover {
      background-position: left;
      border-color: var(--wipfli-blue);
    }

    @include break-md {
      font-size: toRem(18);
      padding: toRem(14) toRem(26);
    }

    &-contain {
      margin-top: toRem(14);

      @include break-md {
        margin-top: toRem(31);
      }
    }
  }

  &__inner-content {
    img {
      display: none;
    }
  }

  &__video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;

    video {
      object-fit: cover;
    }

    &-modal {
      background-color: rgba($color: #63666a, $alpha: 0.8);
      position: relative;
      max-width: toRem(494);
      margin: 0 auto;
      transform: scale(0);
      transition: transform 0.4s ease-in-out;
      width: 100%;

      @include break-md {
        max-width: toRem(624);
      }

      @include break-xl {
        margin-top: toRem(20);
        max-width: toRem(1110);
      }

      &-close-btn {
        background-color: transparent;
        border: none;
        position: absolute;
        height: toRem(20);
        width: toRem(21);
        right: 0;
        top: toRem(-30);
        z-index: 1;

        &::after,
        &::before {
          position: absolute;
          left: toRem(8);
          content: ' ';
          height: toRem(16);
          width: toRem(2);
          background-color: var(--wipfli-white);
          top: 0;
        }

        &::after {
          transform: rotate(-45deg);
        }

        &::before {
          transform: rotate(45deg);
        }

        &:focus {
          outline: none;
          border: toRem(2) dotted var(--wipfli-white);
        }
      }

      &-contain {
        position: relative;
        padding-top: 56.25%;
        height: 0;
      }

      &-container {
        align-items: center;
        background-color: rgba($color: #000000, $alpha: 0.6);
        display: flex;
        justify-content: center;
        inset: 0;
        padding: 0 toRem(20);
        position: fixed;
        pointer-events: none;
        opacity: 0;
        visibility: hidden;

        transition: all 0.2s ease-in-out;

        &.show-modal {
          opacity: 1;
          visibility: visible;
          pointer-events: all;
          z-index: 9999;

          .hero__video-modal {
            transform: scale(1);
          }
        }
      }
    }
  }

  &-imagegallery-variation__one {
    [data-id='hero-first-image'] {
      @include break-lg {
        right: toRem(407);
        top: 42%;
        transform: translateY(-50%);
        z-index: -1;
      }
    }

    [data-id='hero-second-image'] {
      @include break-lg {
        right: toRem(72);
        top: 10%;
        z-index: -2;
      }
    }

    [data-id='hero-third-image'] {
      @include break-lg {
        top: 60%;
        right: 0;
      }
    }
  }

  &-imagegallery-variation__two {
    [data-id='hero-first-image'] {
      @include break-lg {
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    [data-id='hero-second-image'] {
      @include break-lg {
        right: 9.875rem;
        top: 60%;
      }
    }

    [data-id='hero-third-image'] {
      @include break-lg {
        top: 8%;
        right: 18.75rem;
      }
    }
  }

  &-imagegallery-variation__three {
    [data-id='hero-first-image'] {
      @include break-lg {
        right: 3.6875rem;
        top: 61%;
        transform: translateY(-50%);
      }
    }

    [data-id='hero-second-image'] {
      @include break-lg {
        right: 0;
        top: 10%;
      }
    }

    [data-id='hero-third-image'] {
      @include break-lg {
        top: 49%;
        right: 24.125rem;
      }
    }
  }

  &-imagegallery-variation__four {
    [data-id='hero-first-image'] {
      @include break-lg {
        right: toRem(430);
        top: 45%;
        transform: none;
        z-index: -3;
      }
    }

    [data-id='hero-second-image'] {
      @include break-lg {
        right: 0;
        top: 8%;
        z-index: -1;
      }
    }

    [data-id='hero-third-image'] {
      @include break-lg {
        top: 53%;
        right: toRem(140);
        z-index: -2;
      }
    }
    
  }

  &[data-full-image='true'] {   

    .hero__container {
      @include mobile-only {
        padding: 0;
      }
    }

    .hero__inner-content {
      @include mobile-only {
        position: relative;
        z-index: 0;
        padding: toRem(107) toRem(20) toRem(85);
      }

      img {
        @include mobile-only {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
          right: 0;
          width: 100%;
          z-index: -1;
          height: 100%;
        }
      }
    }

    [data-id='hero-first-image'] {
      display: none;
    }

    [data-id='hero-bg-image'] {
      display: none;

      @include break-md {
        animation: none;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        right: 0;
        width: 100%;
        max-width: 100%;
        z-index: -1;
        height: 100%;
        transform: none;
      }
    }

    [data-id='hero-second-image'] {
      display: none;
    }

    [data-id='hero-third-image'] {
      display: none;
    }

    .hero__description-contain {
      @include mobile-only {
        padding: toRem(33) toRem(20) toRem(54);
      }
    }

    .hero__description {
      @include break-md {
        animation: none;
      }
    }

    .hero__title {
      max-width: 100%;

      @include mobile-only {
        margin-bottom: 0;
      }

      @include break-sm {
        max-width: toRem(636);
      }
    }
  }

  &__images {
    @include break-lg {
      visibility: hidden;
      opacity: 0;
      &.show-image {
        visibility: visible;

        &[data-id='hero-first-image'] {
          animation: fadeInHeroImage 1s ease-out;
          animation-fill-mode: forwards;
          animation-delay: 1.2s;
        }

        &[data-id='hero-second-image'] {
          animation: fadeInHeroImage 1s ease-out;
          animation-fill-mode: forwards;
          animation-delay: 0.6s;
        }

        &[data-id='hero-third-image'] {
          animation: fadeInHeroImage 1s ease-out;
          animation-fill-mode: forwards;
        }
      }
    }
  }
}

[data-id='hero-first-image'],
[data-id='hero-second-image'],
[data-id='hero-third-image'] {
  position: absolute;
  object-fit: cover;
}

[data-id='hero-first-image'] {
  height: toRem(248);
  max-width: toRem(186);
  right: 0;
  z-index: -1;
  top: 18%;

  @include break-lg {
    height: toRem(319);
    max-width: toRem(219);
  }
}

[data-id='hero-second-image'] {
  display: none;

  @include break-lg {
    display: block;
    height: toRem(238);
    max-width: toRem(392);
    z-index: -2;
  }
}

[data-id='hero-third-image'] {
  display: none;

  @include break-lg {
    display: block;
    height: toRem(188);
    max-width: toRem(319);
    z-index: -3;
  }
}

@keyframes fadeInHeroImage {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}