@import '../../scss/common';

.button {
  border: 2px solid var(--wipfli-white);
  padding: 11px 43px 10px 20px;
  position: relative;
  color: var(--wipfli-white);
  display: flex;
  font-size: 14px;
  font-weight: 600;
  width: fit-content;
  line-height: normal;
  background: linear-gradient(to left, var(--wipfli-blue) 50%, var(--wipfli-white) 50%) right;
  background-size: 200%;
  transition: 0.25s ease-out;

  @include break-md {
    font-size: 18px;
    padding: 14px 47px 14px 26px;
  }

  &:any-link {
    color: var(--wipfli-white);
    text-decoration: none;
  }

  &::after {
    content: '';
    height: 18px;
    width: 18px;
    position: absolute;
    right: 21px;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    background-image: url('../../../public/local/Home/Project/demo-site/demo-site/white.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }

  &:hover {
    background-position: left;
    border-bottom: 2px solid var(--wipfli-white);
    color: var(--wipfli-blue);
    text-decoration: none;

    &::after {
      background-image: url('../../../public/local/Home/Project/demo-site/demo-site/blue.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }
  }

  &:active {
    border: 2px solid var(--wipfli-light-gray);
    background-position: left;
  }

  &.btn-black {
    background: linear-gradient(to left, var(--wipfli-black) 50%, var(--wipfli-blue) 50%) right;
    background-size: 200%;
    transition: 0.25s ease-out;
    border: 2px solid var(--wipfli-blue);

    &:hover {
      background-position: left;
      color: var(--wipfli-white);

      &::after {
        background-image: url('../../../public/local/Home/Project/demo-site/demo-site/white.svg');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &:active {
      background-position: left;
      border: 2px solid var(--wipfli-white);
      background-color: var(--wipfli-white);
    }
  }
  &.btn-trans-blue {
    background: linear-gradient(to left, transparent 50%, var(--wipfli-blue) 50%) right;
    background-size: 200%;
    transition: 0.25s ease-out;
    border: 2px solid var(--wipfli-blue);

    &:hover {
      background-position: left;
      color: var(--wipfli-white);

      &::after {
        background-image: url('../../../public/local/Home/Project/demo-site/demo-site/white.svg');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &:active {
      background-position: left;
      border: 2px solid var(--wipfli-white);
      background-color: var(--wipfli-white);
    }
  }
  &.btn-close {
    border: 2px solid var(--wipfli-white);
    background: linear-gradient(to left, var(--wipfli-blue) 50%, var(--wipfli-white) 50%) right;
    background-size: 200%;
    transition: 0.25s ease-out;

    &::after {
      background-image: url('../../../public/local/Home/Project/demo-site/demo-site/close.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }

    &:hover {
      background-color: var(--wipfli-white);
      background-position: left;
      color: var(--wipfli-blue);
    }

    &:active {
      border: 2px solid var(--wipfli-light-gray);
      background-position: left;
    }
  }
  &.btn-white {
    background: linear-gradient(to left, var(--wipfli-white) 50%, var(--wipfli-blue) 50%) right;
    background-size: 200%;
    transition: 0.25s ease-out;
    border: 2px solid var(--wipfli-blue);
    color: var(--wipfli-blue);
    &::after {
      content: '';
      background-image: url('../../../public/local/Home/Project/demo-site/demo-site/blue.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }
    &:hover {
      background-position: left;
      color: var(--wipfli-white);
      background-color: var(--wipfli-white);
      &::after {
        background-image: url('../../../public/local/Home/Project/demo-site/demo-site/white.svg');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &:active {
      border: 2px solid var(--wipfli-light-gray);
      background-position: left;
    }
  }

  &.btn-right-transparent {
    background: linear-gradient(to left, transparent 50%, var(--wipfli-white) 50%) right;
    background-size: 200%;

    &:hover {
      background-position: left;
      border-bottom: 2px solid var(--wipfli-white);
      color: var(--wipfli-blue);
      text-decoration: none;
      background-color: var(--wipfli-white);
      &::after {
        background-image: url('../../../public/local/Home/Project/demo-site/demo-site/blue.svg');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &:active {
      border: 2px solid var(--wipfli-light-gray);
      background-position: left;
    }
  }
  &.btn-down-transparent {
    background: linear-gradient(to left, transparent 50%, var(--wipfli-white) 50%) right;
    background-size: 200%;
    &::after {
      background-image: url('../../../public/local/Home/Project/demo-site/demo-site/cta-down.svg');
      background-repeat: no-repeat;
      background-size: contain;
      top: 60%;
      transform: translateY(-60%);
    }

    &:hover {
      background-position: left;
      border-bottom: 2px solid var(--wipfli-white);
      color: var(--wipfli-blue);
      text-decoration: none;
      background-color: var(--wipfli-white);
      &::after {
        background-image: url('../../../public/local/Home/Project/demo-site/demo-site/cta-down.svg');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &:active {
      border: 2px solid var(--wipfli-light-gray);
      background-position: left;
    }
  }
  &.btn-play-transparent {
    background: linear-gradient(to left, transparent 50%, var(--wipfli-white) 50%) right;
    background-size: 200%;
    &::after {
      background-image: url('../../../public/local/Home/Project/demo-site/demo-site/play.svg');
      background-repeat: no-repeat;
      background-size: contain;
    }

    &:hover {
      background-position: left;
      border-bottom: 2px solid var(--wipfli-white);
      color: var(--wipfli-blue);
      text-decoration: none;
      background-color: var(--wipfli-white);
      &::after {
        background-image: url('../../../public/local/Home/Project/demo-site/demo-site/play.svg');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &:active {
      border: 2px solid var(--wipfli-light-gray);
      background-position: left;
    }
  }
}