@import '../../scss/common';

.page-breaker {
  background-color: var(--wipfli-blue);
  position: relative;
  overflow: hidden;
  padding: toRem(77) 0 toRem(72);
  width: 100%;
  z-index: 0;

  &__container {
    @include container-width;
    padding: 0 toRem(20);
  }

  &__inner-contain {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  &__title {
    color: var(--wipfli-white);
    font-family: var(--secondary-font-family);
    font-size: toRem(20);
    font-weight: 500;
    line-height: 1.5;
    margin: 0;
    i{

      font-style: italic;
    }
    @include break-md {
      font-size: toRem(35);
      line-height: 1.35;
    }
  }

  &__bg-title {
    color: rgba($color: #0019ff, $alpha: 0.6);
    font-family: var(--title-font-family);
    font-size: toRem(180);
    font-weight: normal;
    line-height: 0.48;
    letter-spacing: toRem(-6.28);
    white-space: nowrap;
    text-transform: uppercase;
    margin: 60px;

    &:not(:first-child) {
      margin-left: toRem(20);
    }

    @include break-md {
      font-size: toRem(300);
      line-height: 0.43;
    }

    &-contain {
      position: absolute;
      inset: 0;
      width: auto;
      z-index: -1;
      overflow: hidden;
    }

    &-inner-contain {
      align-items: center;
      display: flex;
      height: 100%;
      width: 100%;
      position: absolute;
      inset: 0;
    }
  }

  @include break-md {
    padding: toRem(160) 0;
  }
}
