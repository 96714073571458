:root {
  --video-width: 100vw;
  --video-height: 100vh;
  --homepage-hero-color: var(--wipfli-white);

  @media (min-aspect-ratio: 16/9) {
    --video-height: 56.25vw;
  }

  @media (max-aspect-ratio: 16/9) {
    --video-width: 177.78vh;
  }
}

@import '../../scss/common';

.homepage-hero {
  position: relative;

  &__bg {
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  .desktop {
    @include break-md {
      display: none;
    }
    @include break-lg {
      display: block;
    }
  }
  .mobile {
    @include break-md {
      display: block;
    }
    @include break-lg {
      display: none;
    }
  }
  iframe,
  video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }

  &__overlay {
    position: absolute;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    bottom: 0;
    display: block;
    width: 100%;
  }

  &__content {
    position: relative;
    z-index: 2;
    top: 50%;
    bottom: 0;
    display: flex;
    color: var(--homepage-hero-color);
    width: 100%;
    flex-direction: column;
    gap: 55px;
    padding: toRem(275) toRem(20) toRem(166);
    justify-content: space-between;
    height: fit-content;

    .btn-right-transparent {
      color: var(--wipfli-white);
      border-color: var(--wipfli-white);

      &:hover {
        border-color: var(--wipfli-white);
        color: var(--wipfli-blue);
      }
    }

    button.button {
      &::after {
        -webkit-mask: url('../../../public/local/Home/Project/demo-site/demo-site/cta-arrow-down.svg')
          no-repeat 50% 50%;
        mask: url('../../../public/local/Home/Project/demo-site/demo-site/cta-arrow-down.svg')
          no-repeat 50% 50%;
        background-color: var(--wipfli-white);
      }

      &:hover::after {
        background-color: var(--wipfli-blue);
      }
    }

    @include break-md {
      padding: toRem(259) 20px;
      max-width: 1110px;
      margin: 0 auto;

      & > * {
        max-width: 80%;
      }
    }

    @include break-xl {
      gap: 74px;
      padding-inline: 0;
    }

    @include break-xl {
      & > * {
        max-width: 83%;
      }
    }

    a,
    button {
      opacity: 0;
      animation: fadeInHomepageHero 0.25s ease-in;
      animation-delay: 2.5s;
      animation-fill-mode: forwards;
    }
  }

  h1 {
    position: relative;
    text-transform: uppercase;
  }

  h1 > span {
    display: block;

    & + span {
      opacity: 0;
      animation: fadeInHomepageHero 1.5s ease-in;
      animation-fill-mode: forwards;
      animation-delay: 2.25s;
    }

    &:first-child:not(:last-child) {
      opacity: 0;
      animation: fadeInHomepageHerolineOne 2s ease-out;
      animation-delay: 0.8s;
      animation-fill-mode: forwards;
    }

    &:only-child {
      display: block;
      opacity: 0;
      animation: fadeInHomepageHero 0.5s ease-out;
      animation-delay: 1s;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes fadeInHomepageHerolineOne {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  88% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

@keyframes fadeInHomepageHero {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}