@import "../../scss/common";

.article-page-accordion {
  &-container {
    width: 100%;
    max-width: 730px;
    margin: 0 auto;
    padding-left: 28px;
    padding-right: 28px;
    transition: max-width .4s ease-in-out;
    &.flex {
      width: 100%;
      max-width: 1110px;
      transition: max-width .3s ease-in-out;
    }
    @include break-md {
      padding-left: 20px;
      padding-right: 20px;
    }
    @include break-xl {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
  &__items {
    margin: 0;
    padding: 0;
    list-style-type: none;
    padding-block: 20px;
    @include break-md {
      padding-block: 40px;
    }
    &-lists {
      border-top: 2px solid var(--wipfli-blue);
      border-bottom: 2px solid var(--wipfli-blue);
      position: relative;
      top: 2px;
      padding-block: 24px;
      overflow: hidden;
      &:first-child {
        top: 4px;
      }
    }
    &-contain {
      border: none;
      width: 100%;
      background: transparent;
      display: flex;
      justify-content: space-between;
      &-title {
        color: var(--wipfli-blue);
        font-size: 14px;
        font-weight: 600;
        line-height: 1.57;
        letter-spacing: normal;
        margin: 0;
        text-align: left;
        @include break-md {
          max-width: 635px;
          font-size: 18px;
          line-height: 1.44;
          transition: max-width .4s ease-in-out;
        }
        &.flex {
          width: 100%;
          max-width: 1010px;
          transition: max-width .3s ease-in-out;
        }
        &-subtext {
          color: var(--wipfli-blue);
          font-weight: normal;
          letter-spacing: normal;
          text-align: left;
          margin-left: 5px;
          @include break-md {
            font-size: 18px;
            line-height: 1.44;
          }
        }
      }
      &-icon {
        &-expand {
          display: block;
          height: 28px;
          width: 28px;
        }
        &-collapse {
          display: block;
          height: 28px;
          width: 28px;
        }
      }
      &-content {
        max-height: 0px;
        overflow: hidden;
        transition: max-height .3s ease-in-out;
        @include break-md{
          transition: max-height .3s ease-in-out;
        }
        @include break-lg{
          transition: unset;
        }
      }
      &-content-show {
        height: auto;
        max-height: 1000px;
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding-top: 30px;
        padding-bottom: 24px;
        transition: max-height .5s ease-in-out;
        @include break-md {
          padding-top: 51px;
          padding-bottom: 38px;
          transition: max-height .5s ease-in-out;
        }
        @include break-lg {
          flex-direction: row;
          gap: 40px;
          transition: unset;
        }
        &-left {
          width: 100%;
          flex: 1;
          &-title {
            margin-bottom: 10px;
            color: var(--wipfli-core-black);
            @include break-md {
              font-size: 34px;
              line-height: 1.06;
              margin-bottom: 20px;
            }
          }
          &-desc {
            color: var(--wipfli-core-black);
            @include break-md {
              font-size: 18px;
              line-height: 1.44;
            }
          }
        }
        &-right {
          width: 100%;
          @include break-lg {
            width: 50%;
          }
          img {
            height: auto; 
            width: auto;
            @include break-md{
              height: 326px;
            }
          }
        }
      }
    }
  }
}
