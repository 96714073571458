@import '../../scss/common';

.fig {
  &-container {
    --fig-height: 100vh;
    --fig-maxheight: 620px;
    --fig-image-height: var(--fig-maxheight);
    --fig-frame-space: 4rem 30px;
    --fig-content-space: 0 30px;
    --fig-title-size: 50px;
    --fig-slide-height: 55%;
    --fig-slide-width: 100%;
    --fig-slide-inset: auto 0 0;
    --fig-pagination-space: 1rem;
    --fig-pagination-fn-size: 14px;
    --fig-pagination-fn-lh: 22px;
    --fig-pagination-count-space: 29px;
    --fig-navigation-icons-size: 25px;
    --fig-navigation-icons-space: 9px;
    --fig-eyebrow-fn-size: 24px;
    --fig-eyebrow-fn-lh: normal;
    --fig-eyebrow-ls: normal;
    --fig-link-fn-size: 14px;
    --fig-link-lh: normal;
    --fig-link-ls: normal;
    --fig-link-fn-weight: 600;
    --fig-link-icon-top: 1px;
    --fig-viewAll-border-color: var(--wipfli-white);
    --fig-viewAll-bg: linear-gradient(to left, var(--wipfli-black) 50%, var(--wipfli-blue) 50%)
      right;

    @media only screen and (min-width: 768px) {
      --fig-height: 100vh;
      --fig-maxheight: 100vh;
      --fig-image-height: var(--fig-maxheight);
      --fig-frame-space: 4rem 10vw;
      --fig-content-space: 0 10vw;
      --fig-title-size: 110px;
      --fig-slide-height: 100%;
      --fig-slide-width: 65%;
      --fig-slide-inset: 0 0 0 auto;
      --fig-pagination-space: 3.8rem;
      --fig-pagination-fn-size: 14px;
      --fig-pagination-fn-lh: 22px;
      --fig-pagination-count-space: 46px;
      --fig-navigation-icons-size: 35px;
      --fig-navigation-icons-space: 18px;
      --fig-eyebrow-fn-size: 34px;
      --fig-eyebrow-fn-lh: 36px;
      --fig-eyebrow-ls: -0.1px;
      --fig-link-fn-size: 18px;
      --fig-link-ls: -0.04px;
      --fig-link-icon-top: 4px;
      --fig-viewAll-border-color: var(--wipfli-blue);
      --fig-viewAll-bg: linear-gradient(to left, transparent 50%, var(--wipfli-blue) 50%) right;
    }

    @media only screen and (min-height: 900px) {
      --fig-frame-space: 90px 10vw;
      --fig-content-space: 5rem 10vw 0;
      --fig-maxheight: 970px;
    }

    width: 100%;
    height: var(--fig-height);
    max-height: var(--fig-maxheight);
    min-height: 600px;
    overflow: hidden;
    background-color: #000;
    position: relative;
    z-index: 0;

    .frame {
      position: absolute;
      text-align: left;
      z-index: 100;
      top: 0;
      left: 0;
      display: grid;
      align-content: space-between;
      width: 100%;
      max-width: none;
      height: var(--fig-height);
      min-height: 600px;
      max-height: var(--fig-maxheight);
      padding: var(--fig-frame-space);
      pointer-events: none;
      grid-template-columns: 100%;
      grid-template-rows: auto auto;
      grid-template-areas:
        'nav'
        'button';

      a,
      button {
        pointer-events: auto;
      }

      @include break-xl {
        padding: 4rem 13vw;
      }
    }

    .slideshow {
      width: 100vw;
      height: var(--fig-image-height);
      min-height: 600px;
      max-height: 970px;
      position: relative;
      overflow: hidden;
      background: var(--color-bg);
    }

    .slide {
      margin: 0;

      @media only screen and (max-width: 768px) {
        &:before {
          content: '';
          position: absolute;
          background: #000;
          height: 45%;
          width: 100%;
          z-index: 1;
          top: 0;
        }
      }
    }

    .slide,
    .slide__img-wrap,
    .slide__img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    .slide {
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;

      .slides__caption-headline {
        a {
          &:any-link {
            text-decoration: none;
            border-bottom: none;
          }
        }

        @media only screen and (min-width: 1024px) and (max-width: 1600px) {
          margin-top: 45px;
        }
      }

      &--current {
        opacity: 1;
        z-index: 10;
      }
    }

    .slide__img-wrap {
      will-change: transform;
      overflow: hidden;
      translate: none;
      rotate: none;
      scale: none;
      clip-path: inset(0% 0% 0% 0%);
      transform: translate(0px, 0%) rotate(0.001deg);
      height: var(--fig-slide-height);
      inset: var(--fig-slide-inset);
      width: var(--fig-slide-width);
      overflow: hidden;
    }

    .slide__img {
      background-size: cover;
      background-position: center center;
      will-change: transform;
      -webkit-backspace-visibility: hidden;
      translate: none;
      rotate: none;
      scale: none;
      transform: translate(0px, 0%);
      z-index: 0;

      &::after {
        background: rgba($color: #000000, $alpha: 0.4);
        content: '';
        position: absolute;
        inset: 0;
        width: 100%;
        z-index: -1;
      }
      @include break-lg {
        &::after {
          content: none;
        }
      }
    }

    .slide__caption {
      position: relative;
      padding: var(--fig-content-space);
      cursor: default;
      width: 100%;
      z-index: 2;

      @include break-xl {
        padding: 0 13vw;
      }
    }

    .text-row {
      position: relative;
      overflow: hidden;
      display: block;
      z-index: 10;
    }

    .text-row > span {
      display: block;
      position: relative;
    }
  }

  &-eyebrow {
    text-transform: uppercase;
    color: var(--wipfli-blue);
    font-family: var(--title-font-family);
    font-size: 24px;

    @include break-lg {
      font-size: 34px;
      line-height: 1.06;
      letter-spacing: -0.1px;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1600px) {
      margin-top: 45px;
    }

    @media only screen and (min-width: 1601px) {
      margin-top: 90px;
    }
  }

  &-pagination {
    display: flex;
    margin-top: var(--fig-pagination-space);
  }

  &-controls {
    display: flex;
  }

  &-control {
    &--prev,
    &--next {
      cursor: pointer;
      border: 0;
      appearance: none;
      background-color: transparent;
      width: var(--fig-navigation-icons-size);
      height: var(--fig-navigation-icons-size);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      width: 35px;

      &:hover {
        svg {
          g {
            fill: var(--wipfli-blue);
            stroke: var(--wipfli-blue);
          }

          path {
            stroke: var(--wipfli-white);
          }
        }
      }
    }

    &--next {
      margin-left: var(--fig-navigation-icons-space);
    }
  }

  &-count {
    color: var(--wipfli-white);
    display: flex;
    align-items: center;
    font-size: var(--fig-pagination-fn-size);
    font-family: var(--primary-font-family);
    line-height: var(--fig-pagination-fn-size);
    font-weight: 500;
    margin-left: var(--fig-pagination-count-space);
  }

  &-textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-block: 58px;

    @include mobile-only {
      margin-top: -40px;
    }

    .description {
      display: block;
      font-size: var(--fig-title-size);
      line-height: 1;
      letter-spacing: normal;
      color: var(--wipfli-white);
      text-transform: uppercase;
      font-family: 'Anton', sans-serif;

      @include mobile-only {
        font-size: toRem(50);
      }

      .split-item {
        display: block;
      }
    }

    .slides__caption-link {
      color: var(--wipfli-white) !important;
      text-decoration: none !important;
      position: relative;
      display: flex;
      align-items: center;
      border: 0;
      font-size: var(--fig-link-fn-size);
      font-family: var(--primary-font-family);
      line-height: var(--fig-link-lh);
      letter-spacing: var(--fig-link-ls);
      font-weight: var(--fig-link-fn-weight);
      width: max-content;
      min-height: 25px;
      position: relative;

      &:any-link {
        &:hover {
          border-bottom: none;
          text-decoration: underline !important;
        }
      }

      &::after {
        content: '';
        height: 15px;
        width: 15px;
        display: block;
        background-image: url('../../../public/local/Home/Project/demo-site/demo-site/fly-in-gallery-icon.svg');
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        right: -25px;
        top: 50%;
        transform: translateY(-50%);
      }

      @include break-md {
        &::after {
          height: 21px;
          width: 21px;
          right: -27px;
        }
      }
    }
  }

  &-viewAll {
    margin-top: auto;
    padding: 10px 34px 10px 20px;
    border-color: var(--fig-viewAll-border-color) !important;
    background: linear-gradient(to left, transparent 50%, var(--wipfli-white) 50%) right;
    background-size: 200%;

    &:hover {
      background-position: left;
      border-bottom: 2px solid var(--wipfli-white);
      color: var(--wipfli-blue);
      text-decoration: none;
      background-color: var(--wipfli-white);

      &::after {
        background-image: url('../../../public/local/Home/Project/demo-site/demo-site/blue.svg');
        background-repeat: no-repeat;
        background-size: contain;
      }
    }

    &:active {
      border: 2px solid var(--wipfli-light-gray);
      background-position: left;
    }

    @media only screen and (min-width: 768px) {
      background: linear-gradient(to left, transparent 50%, var(--wipfli-blue) 50%) right;
      background-size: 200%;

      &:hover {
        color: var(--wipfli-white);
        background-color: var(--wipfli-blue);

        &::after {
          background-image: url('../../../public/local/Home/Project/demo-site/demo-site/white.svg');
        }
      }
    }

    @media only screen and (min-width: 1024px) and (max-width: 1600px) {
      margin-bottom: 45px;
    }

    @media only screen and (min-width: 1601px) {
      margin-bottom: 90px;
    }

    &:after {
      right: 7px;
      background-position: 0 0;
    }
  }
}
