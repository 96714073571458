@import '../../scss/common';
.hidesection {
  background-color: var(--wipfli-white);
  color: var(--wipfli-core-black);
  position: absolute;
  padding: 12px;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  text-align: center;
  text-decoration: underline;
}

.react-select-container {
  .react-select__control {
    background: transparent;
    font-size: 1.125rem;
    padding: 10px 0px 10px 15px;
    border: 2px solid var(--multiselect-color);
    border-radius: 0;
    color: var(--multiselect-color);
    font-family: inherit;
    &:hover {
      border-color: var(--multiselect-color);
    }
    .react-select__value-container {
    }
    .react-select__indicators {
      .react-select__indicator {
        color: var(--multiselect-color);
      }
      .react-select__indicator-separator {
        display: none;
      }
    }
    .react-select__placeholder {
      color: var(--multiselect-color);
      font-weight: 500;
      &:hover {
        border-color: var(--multiselect-color);
      }
    }
    .react-select__multi-value {
      background-color: var(--multi-value-bg-color);
      border: 1px solid var(--multi-value-bg-color);
      .react-select__multi-value__label {
        color: var(--multi-value-color);
        font-family: inherit;
      }
      .react-select__multi-value__remove {
        background: var(--multi-value-remove-bg-color);
        &:hover {
          svg {
            color: var(--multi-value-remove-hover-bg-color);
          }
        }
      }
    }
  }
  .react-select__menu {
    background-color: var(--menu-bg-color);
    border-radius: 0;
    .react-select__menu-list {
      padding: 0 20px;
      .react-select__option {
        background-color: transparent;
        color: var(--menu-color);
        &:hover,
        &:focus {
          background: var(--menu-bg-hover-color);
          color: var(--menu-hover-color);
        }
      }
    }
  }
}

.contact-form {
  //************************************************
  // mobile first

  //************************************************
  // tablet overrides
  @include break-md {
  }

  //************************************************
  // desktop overrides
  @include break-lg {
  }
}
