@import '../../scss/common';

.header {
  --header-position: relative;
  --headerBgColor: var(--wipfli-core-black);

  background-color: transparent;
  position: absolute;
  top: 0;
  z-index: 999;
  width: 100%;
  transform: none;
  transition: all 0.4s ease-in-out;

  &__container {
    padding: 0 toRem(20);

    @include break-md {
      padding: 0 toRem(15);
    }

    @include break-lg {
      @include container-width;
    }

    @include break-xl {
      padding: 0;
    }
  }

  &__inner-contain {
    display: flex;
    justify-content: space-between;
  }

  &__skip-menu {
    background-color: var(--wipfli-white);
    color: var(--wipfli-core-black);
    position: absolute;
    padding: 12px;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    text-align: center;
    text-decoration: underline;

    &:focus {
      left: 0;
      width: 100%;
      height: auto;
      z-index: 1000;
    }
  }

  &__inner-container {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    align-items: center;
  }

  &-logo-contain {
    img {
      width: toRem(233);
      height: toRem(50) !important;
      object-fit: cover;

      @include break-lg {
        width: toRem(379);
        height: toRem(80) !important;
      }
    }
  }

  &__menu-btn {
    background: transparent;
    border: none;
    padding: 3px;
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;

    &-contain {
      align-items: center;
      display: flex;
    }

    &-container {
      position: relative;
      height: toRem(12);
      width: toRem(22);
      display: flex;
    }

    &-bar-one,
    &-bar-two {
      display: block;
      position: absolute;
      height: 1px;
      width: 100%;
      border-bottom: 2px solid var(--wipfli-white);
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.3s ease-in-out;
    }

    &-bar-one {
      top: 0px;
      transform-origin: left center;
    }

    &-bar-two {
      top: toRem(11);
      transform-origin: left center;
    }

    &-open {
      .header__menu-btn-bar-one {
        transform: rotate(45deg);
        top: toRem(-3);
        left: toRem(-3);

        @include break-md {
          left: toRem(3);
        }
      }

      .header__menu-btn-bar-two {
        transform: rotate(-45deg);
        top: toRem(13);
        left: toRem(-3);

        @include break-md {
          left: toRem(3);
        }
      }
    }
  }

  &__nav-contain {
    background-color: var(--wipfli-white);
    display: flex;
    flex-direction: column;
    height: 100vh;
    left: 0;
    opacity: 0;
    position: fixed;
    top: toRem(50);
    transform: translate(100%);
    transition: all 0.3s ease-in-out;
    width: 100%;
    visibility: hidden;
    padding: toRem(35) toRem(20) 13em;

    @include break-lg {
      width: 40%;
      left: auto;
      right: 0;
      top: 0;
      padding: toRem(83) 0 0 toRem(62);
    }
  }

  &__nav {
    &-list {
      list-style: none;
      padding: 0;
      position: relative;

      &-item {
        margin-bottom: toRem(20);

        &-btn,
        &-link {
          background: transparent;
          border: none;
          color: var(--wipfli-core-black);
          font-family: var(--title-font-family);
          font-size: toRem(24);
          letter-spacing: -0.05px;
          line-height: normal;
          position: relative;
          text-transform: uppercase;

          &:any-link {
            color: var(--wipfli-core-black);
            text-decoration: none;
          }

          @include break-md {
            font-size: toRem(22);
          }
        }

        &-btn {
          display: inline-block;
          position: relative;
          text-align: left;

          &::after {
            display: inline-block;
            margin-left: 12px;
            vertical-align: super;
            content: '';
            border: 0;
            border-top: 2px solid var(--wipfli-core-black);
            border-right: 2px solid var(--wipfli-core-black);
            height: 9px;
            width: 9px;
            transform: rotate(136deg);
            transition: transform 0.3s ease-in-out;
          }

          &-open {
            &::after {
              vertical-align: middle;
              transform: rotate(315deg);
            }
          }

          @include break-lg {
            &::after {
              content: none;
            }
          }
        }
      }
    }
  }

  &__subnav {
    &-list {
      columns: 2;

      @include break-sm {
        max-width: 80%;
      }

      @include break-lg {
        max-width: unset;
      }

      &-item {
        margin-bottom: toRem(12);

        &-btn,
        &-link {
          background: none;
          border: none;
          color: var(--wipfli-blue);
          font-size: toRem(14);
          font-weight: normal;
          letter-spacing: -0.03px;
          line-height: normal;
          transition: all 0.2s ease-in-out;

          &:any-link {
            color: var(--wipfli-core-black);
            text-decoration: none;

            &:hover {
              font-weight: 600;
              color: var(--wipfli-blue);
            }
          }
        }

        &-btn {
          font-weight: 600;
          position: relative;

          &-link-show {
            font-weight: 600;

            &:any-link {
              color: var(--wipfli-blue);
            }
          }

          &::after {
            display: inline-block;
            margin-left: 8px;
            vertical-align: middle;
            content: '';
            border: 0;
            border-top: 2px solid var(--wipfli-core-black);
            border-right: 2px solid var(--wipfli-core-black);
            height: 6px;
            width: 6px;
            transform: rotate(45deg);
            transition: transform 0.3s ease-in-out;
          }

          &:hover {
            &::after {
              border-color: var(--wipfli-blue);
            }
          }

          &-open {
            &::after {
              border-color: var(--wipfli-blue);
            }
          }
        }

        @include break-md {
          margin-bottom: toRem(4);
        }
      }
    }

    &-list,
    &-menu {
      list-style: none;
      padding: 0;
    }

    &-menu {
      &-contain {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;

        &-open {
          opacity: 1;
          visibility: visible;
        }
      }

      &-item {
        margin-bottom: toRem(12);

        @include break-md {
          margin-bottom: toRem(4);
        }
      }
    }

    &-contain {
      margin-top: 0;
      height: 0;
      overflow: hidden;
      opacity: 0;
      visibility: hidden;
      position: relative;
      transition: all 0.3s ease-in-out;

      &.header__subnav-menu-open {
        margin-top: toRem(12);
        height: auto;
        overflow: initial;
        opacity: 1;
        visibility: visible;
      }

      @include break-md {
        margin-top: toRem(4);
        height: auto;
        overflow: initial;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__open-menu {
    background-color: var(--wipfli-white);

    .header__nav-contain {
      position: fixed;
      opacity: 1;
      overflow-y: auto;
      z-index: 20;
      transform: translate(0);
      visibility: visible;

      @include break-lg {
        top: 0;
        right: 0;
        overflow-y: initial;

        .header__nav {
          overflow-y: auto;
        }

        .header__nav-list {
          max-width: 365px;
        }
      }
    }

    .header__menu-btn-open {
      @include break-lg {
        position: relative;
        z-index: 99;
      }
    }

    .header__menu-btn-bar-one,
    .header__menu-btn-bar-two {
      border-bottom: 2px solid var(--wipfli-core-black);
    }

    @include break-lg {
      background-color: transparent;
    }
  }

  @at-root .experience-editor & {
    position: static !important;
    background-color: var(--wipfli-core-black);
    width: 100%;
  }

  @include break-lg {
    &__overlay-open {
      inset: 0;
      position: absolute;
      z-index: 20;
      height: 100vh;
      background-color: rgba($color: #000000, $alpha: 0.4);
    }
  }
}

.hero-full-bleed {
  .header {
    .header__menu-btn-bar-two,
    .header__menu-btn-bar-one {
      background: var(--wipfli-white);
    }

    &.header__open-menu {
      .header__menu-btn-bar-two,
      .header__menu-btn-bar-one {
        background: var(--wipfli-core-black);
      }
    }
  }
}

.header__scroll-up {
  .header {
    background-color: var(--wipfli-blue);
    position: fixed;
    width: 100%;
    transform: none;

    .header__menu-btn-bar-two,
    .header__menu-btn-bar-one {
      border-bottom: 2px solid var(--wipfli-white);
    }

    &.header__open-menu {
      .header__menu-btn-bar-two,
      .header__menu-btn-bar-one {
        @include break-lg {
          border-bottom: 2px solid var(--wipfli-core-black);
        }
      }
    }

    @include break-lg {
      background-color: var(--wipfli-blue);
    }
  }
}

.header__scroll-down {
  .header {
    width: 100%;
    transform: translateY(-200px);
    position: absolute;
    overflow: hidden;
    transition: all 0.2s ease-in-out;
  }
}

body.open-navigation {
  overflow-y: hidden;
  position: fixed;
  width: 100%;
  @include break-lg {
    overflow-y: initial;
    position: static;
    width: 100%;
  }
}

form:not(#scPageExtendersForm) {
  button {
    &::after {
      background-image: url('../../../public/local/Home/Project/demo-site/demo-site/blue.svg');
    }

    &:hover {
      &::after {
        background-image: url('../../../public/local/Home/Project/demo-site/demo-site/white.svg');
      }
    }
  }

  label.invalid {
    ~ input[type='text'],
    ~ input[type='password'],
    ~ input[type='email'],
    ~ input[type='zip'],
    ~ select,
    ~ textarea,
    ~ input[type='tel'],
    ~ input[type='date'],
    ~ input[type='datetime-local'],
    ~ input[type='month'],
    ~ input[type='number'],
    ~ input[type='search'],
    ~ input[type='time'],
    ~ input[type='url'],
    ~ input[type='week'] {
      border-color: var(--form-error-border-clr);
      padding-right: calc(1.5em + 0.75rem);
      background-image: url('../../../public/local/Home/Project/demo-site/demo-site/invalid-name.png');
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.05em + 0.175rem) calc(0.5em + 0.375rem);
    }
  }
}
