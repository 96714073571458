@import '../../scss/common';

.article-cta {
  padding: toRem(40) 0;

  &__title {
    color: var(--wipfli-blue);
    font-family: var(--title-font-family);
    font-size: toRem(24);
    font-weight: normal;
    line-height: 1.08;
    margin-bottom: toRem(18);
    text-transform: uppercase;

    @include break-md {
      font-size: toRem(34);
      line-height: 1.06;
      margin-bottom: toRem(26);
    }
  }

  &__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;

    @include break-md {
      flex-direction: row;
    }
  }

  &__inner-contain {
    flex: 1;
  }

  &__image {
    max-height: toRem(217);
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 30%);
    margin: 0 auto 15px;
    object-fit: cover;
    width: toRem(160);

    &-contain {
      margin-right: toRem(20);

      @include break-md {
        margin-right: toRem(30);
      }
    }

    &-caption {
      color: #63666a;
      font-size: 0.75rem; // 12px
      line-height: 1.67;
      margin-top: toRem(6);

      @include break-lg {
        font-size: 0.87rem; // 14px
        line-height: 1.47;
      }
    }
  }

  &__description {
    margin-bottom: toRem(16);

    &,
    * {
      color: var(--wipfli-core-black);
      font-size: toRem(14);
      line-height: 1.57;

      @include break-md {
        font-size: toRem(18);
        line-height: 1.44;
      }
    }

    @include break-md {
      margin-bottom: toRem(23);
    }
  }

  &__contain {
    .btn {
      padding: 11px 20px 10px;

      &::after {
        content: none;
      }

      @include break-md {
        padding: 14px 26px;
      }
    }
  }

  .article-cta__link,
  .article-cta__button {
    border: 1.8px solid var(--wipfli-blue);
    padding: 11px 20px 10px;
    position: relative;
    color: var(--wipfli-blue);
    font-size: toRem(14);
    font-weight: 600;
    display: flex;
    width: fit-content;
    background: linear-gradient(to left, transparent 50%, var(--wipfli-blue) 50%) right;
    background-size: 200%;
    transition: all 0.25s ease-out !important;

    svg {
      margin-left: toRem(10);
      width: toRem(16);
      height: toRem(19);
      path {
        fill: var(--wipfli-blue);
      }
      @include break-md{
        width: toRem(20);
      height: toRem(23);
      }
    }

    &:any-link {
      color: var(--wipfli-white);
      text-decoration: none;
    }

    &:hover {
      background-position: left;
      border-bottom: 1.8px solid var(--wipfli-blue);
      color: var(--wipfli-white);
      text-decoration: none;

      svg {
        path {
          fill: var(--wipfli-white);
        }
      }
    }

    @include break-md {
      font-size: toRem(18);
      padding: 14px 26px;
    }
  }

  .article-cta__link {
    display: flex;
    align-items: center;
    padding: 11px 27px 10px 29px;

    &:any-link {
      color: var(--wipfli-blue);

      &:hover {
        color: var(--wipfli-white);
      }
    }
    @include break-md{
      padding: 12px 27px 12px 29px;
    }
  }

  .ebook-modal__close-btn {
    border: none;   
    padding: 0;
    outline: 0;
    display: block;
    &:hover {
      background-color: transparent;
    }
  }

  @include break-md {
    padding: toRem(80) 0 toRem(60);
  }
}