@import "../../scss/common";

.featured-items {
  &-container {
    width: 100%;
    max-width: 1110px;
    margin: 0 auto;
    padding: 53px 28px 56px 28px;
    @include break-md {
      padding-block: 0;
      padding-left: 20px;
      padding-right: 20px;
      margin-block: 89px;
    }
    @include break-xl {
      padding-left: 0;
      padding-right: 0;
    }
    &__controls {
      position: absolute;
      top: 50px;
      right: 30px;
      @include break-md {
        top: 92px;
        right: 30px;
      }
      @include break-lg {
        top: 92px;
        right: 0;
      }
    }
  }
  &__title {
    color: var(--wipfli-blue);
    font-weight: normal;
    text-transform: uppercase;
    width: fit-content;
    margin: 0;
    @include break-md {
      font-size: 34px;
      line-height: 1.06;
    }
  }
  &__contain {
    position: relative;
    @include break-md {
      padding-top: 30px;
    }
    &-link {
      &:any-link {
        text-decoration: none;
      }
      &-item {
        &-text {
          align-items: center;
          border-bottom: none;
          display: inline-flex;
          font-size: toRem(14);
          font-weight: 600;
          line-height: normal;
          position: relative;
          text-decoration: none;

          svg {
            margin-left: toRem(4);
            height: toRem(13);
            width: toRem(13);

            @include break-md {
              height: toRem(18);
              width: toRem(18);
            }
          }

          &:any-link,
          &:hover {
            border-bottom: none;
            text-decoration: none;
          }

          &:hover {
            span {
              text-decoration: underline;
            }
          }

          @include break-md {
            font-size: toRem(18);
          }
        }
      }
    }
    &-img {
      position: relative;
      cursor: pointer;
      overflow: hidden;
      img{
        object-fit: cover;
        width: 100%;
        height: 260px;

      }
      &::after {
        
        content: "";
        inset: 0;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        
      }
    }
    .swiper{
      position: unset;
      .swiper-slide{
        height: auto;
      }
      .swiper-button-prev {
        border-radius: 50%;
        border: none;
        background: transparent;
        border: 2px solid var(--wipfli-blue);
        transform: rotateY(187deg);
        background-image: url("../../../public/local/Home/Project/demo-site/demo-site/blue.svg");
        background-repeat: no-repeat;
        background-position: center;
        height: 25px;
        width: 25px;
        background-size: 8px;
        top: -9px;
        left: auto;
        right: 40px;
        background-position: 8px;
        &:hover {
          background-color: var(--wipfli-blue);
          background-image: url("../../../public/local/Home/Project/demo-site/demo-site/white.svg");
          background-repeat: no-repeat;
        }
        @include break-md{
          background-size: 12px;
          background-position: 11px;
          height: 35px;
          width: 35px;
          top: -14px;
          left: auto;
          right: 55px;
        }
        @include break-lg {
          left: auto;
          right: 53px;
        }
      }
      .swiper-button-next{
        background: transparent;
        border: 2px solid var(--wipfli-blue);
        border-radius: 50%;
        background-image: url("../../../public/local/Home/Project/demo-site/demo-site/blue.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 8px;
        height: 25px;
        width: 25px;
        top: -9px;
        right: 0;
        background-position: 8px;
        &:hover {
          background-color: var(--wipfli-blue);
          background-image: url("../../../public/local/Home/Project/demo-site/demo-site/white.svg");
          background-repeat: no-repeat;
        }
        @include break-md{
          background-size: 12px;
          background-position: 11px;
          height: 35px;
          width: 35px;
          top: -14px;
          right: 0;
        }
      }
      .swiper-button-disabled{
        &:hover{
          background-color: transparent;
           background-image: url("../../../public/local/Home/Project/demo-site/demo-site/blue.svg");
        }
      }
    }
    .swiper-slide {
      border-top: 2px solid var(--wipfli-blue);
      padding-top: 15px;
      @include break-md {
        border-top: none;
        border-left: 2px solid var(--wipfli-blue);
        padding-left: 24px;
        padding-top: 0px;
      }
      &:hover {
        .featured-items__contain-img {
          overflow: hidden;
          img{
            transform: scale(1.15);
            transition: transform .3s ease-in-out;
          }
        }
        .featured-items__contain-title{
              color: var(--wipfli-blue);
              transition: color .3s ease-in-out;
        }
      }
    }
    &-title {
      text-transform: uppercase;
      font-weight: normal;
      color: var(--wipfli-black);
      padding-top: 10px;
      @include break-md {
        line-height: 1.06;
        padding-top: 27px;
        padding-bottom: 16px;
      }

    }
    &-description {
      color: var(--wipfli-black);
      @include break-md {
        margin-bottom: 27px;
      }
    }
  }
}
