@import "../../scss/common";

.featured-article {
  &-container {
    width: 100%;
    max-width: 1110px;
    margin: 0 auto;
    display: flex;
    padding: 60px 28px 28px;

    @include break-md {
      padding: 120px 20px 60px;
      gap: 30px;
    }

    @include break-xl {
      padding-inline: 0px;
    }
  }

  &__inner-contain {
    margin: 0;
    padding: 0;
    list-style-type: none;

    &-items {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 22px;

      @include break-md {
        flex-direction: row;
        gap: 30px;
      }

      &-left-align {
        width: 100%;
        position: relative;

        @include break-md {
          width: 50%;
        }

        img {
          min-height: 240px;
          height: 100%;
          object-fit: cover;

          @include break-md {
            min-height: 406px;
          }
        }

        &-label {
          color: var(--wipfli-black);
          background: var(--wipfli-white);
          font-size: 14px;
          font-weight: 600;
          position: absolute;
          top: 20px;
          left: 20px;
          padding: 6px 12px;
        }
      }

      &-right-align {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include break-md {
          border-top: 2px solid var(--wipfli-black);
          width: 50%;
        }

        &-content {
          @include break-md {
            margin-top: 12px;
          }

          &-date {
            color: var(--wipfli-black);
            font-size: 14px;
            font-family: var(--secondary-font-family);
            font-weight: normal;
            line-height: 1.86;
            letter-spacing: normal;
            margin-bottom: 4px;

            @include break-md {
              font-size: 18px;
              line-height: 1.44;
            }
          }

          &-title {
            color: var(--wipfli-black);

            a {
              color: var(--wipfli-black);
              font-family: var(--title-font-family);
              text-transform: uppercase;
              margin-bottom: 20px;
              transition: color 0.3s ease-in-out;
              text-decoration: none;

              @include break-md {
                font-size: 3rem;
                line-height: 3.125rem;
                margin-bottom: 18px;
              }

              &:any-link {
                color: var(--wipfli-black);
                border: none;
                text-decoration: none;
                transition: color 0.3s ease-in-out;

                &:hover {
                  color: var(--wipfli-blue);
                  border: none;
                  text-decoration: none;
                }
              }
            }
          }

          &-desc {
            color: var(--wipfli-black);
            margin-bottom: 20px;

            @include break-md {
              font-size: 1.125rem;
              line-height: 1.625rem;
            }
          }

          &-links {
            width: fit-content;

            a {
              text-decoration: none;
              font-weight: 600;
              font-size: 14px;
              line-height: normal;
              letter-spacing: normal;
              text-decoration: none;
              position: relative;

              @include break-md {
                font-size: 18px;
              }

              &:any-link {
                text-decoration: none;
              }

              &:hover {
                border: none;
                text-decoration: underline;
              }

              &::after {
                content: "";
                position: absolute;
                right: -20px;
                top: 50%;
                transform: translateY(-50%);
                background-image: url("../../../public/local/Home/Project/demo-site/demo-site/blue.svg");
                background-repeat: no-repeat;
                background-size: contain;
                height: 13px;
                width: 13px;

                @include break-md {
                  right: -25px;
                  height: 18px;
                  width: 18px;
                }
              }
            }
          }
        }

        &-tags {
          border-top: 1px solid var(--wipfli-gray);
          display: flex;
          flex-wrap: wrap;
          margin-top: 56px;

          @include break-md {
            margin-top: 0;
          }

          &-items {
            margin: 0;
            padding: 0;
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;
            margin-top: 10px;

            @include break-md {
              margin-top: 8px;
            }

            &-lists {
              font-family: var(--secondary-font-family);
              font-size: 12px;
              letter-spacing: normal;
              line-height: 1.67;

              @include break-md {
                font-size: 15px;
                line-height: 0.8;
              }
            }
          }
        }
      }
    }
  }
}