@import '../../scss/common';

.article-page-author {
  border-top: 2.5px solid var(--wipfli-core-black);
  display: flex;
  flex-wrap: wrap;
  padding-top: toRem(24);
  width: 100%;

  &__list {
    list-style: none;
    padding: 0;

    &-item {
      padding-top: toRem(60);
    }
  }

  &__image-contain {   
    margin-right: toRem(16);

    img {
      height: toRem(72);
      object-fit: cover;
      width: toRem(72);

      @include break-sm {
        width: toRem(160);
        height: toRem(160);
      }
    }

    @include break-sm {     
      margin-right: toRem(30);
    }
  }

  &__contain {
    flex: 1;
  }

  &__name,
  &__role,
  &__description,
  &__link {
    font-family: var(--primary-font-family);
    font-size: toRem(14);
    line-height: 1.57;

    @include break-md {
      font-size: toRem(18);
      line-height: 1.44;
    }
  }

  &__name,
  &__role,
  &__description {

    &,
    * {
      color: var(--wipfli-core-black);
    }
  }

  &__name,
  &__link {
    font-weight: 600;
  }

  &__name {
    margin-bottom: toRem(3);
  }

  &__role {
    margin-bottom: toRem(6);
  }

  &__link {    

    &-contain {
      margin-top: toRem(8);

      @include break-md {
        margin-top: toRem(12);
      }
    }
  }

  @include break-md {
    padding-top: toRem(30);
  }
}