@import '../../scss/common';

.tile-list {
  list-style: none;
  padding: 0;

  @include break-md {
    display: flex;
    flex-wrap: wrap;
    margin: 0 toRem(-15);
  }

  @include break-lg {
    margin: 0 toRem(-14);
  }

  &__comp {
    padding: toRem(57) toRem(20) toRem(27);

    @include break-md {
      padding: toRem(85) toRem(15) toRem(22);
    }

    @include break-xl {
      padding: toRem(85) 0 toRem(22);
    }
  }

  &__container {
    @include break-lg {
      @include container-width;
    }
  }

  &__title {
    color: var(--wipfli-blue);
    font-size: toRem(34);
    line-height: 1.04;
    margin-bottom: toRem(8);
    text-transform: uppercase;

    @include break-md {
      font-size: toRem(48);
      margin-bottom: toRem(25);
    }
  }

  &__item {
    @include break-md {
      padding: 0 toRem(15);
      width: 50%;
    }

    @include break-lg {
      padding: 0 toRem(14);
    }

    &-image-contain {
      position: relative;
      overflow: hidden;
      img {
        object-fit: cover;
        max-height: 260px;
        width: 100%;       
      }

      &::after {
        content: "";
        inset: 0;
        position: absolute;
        visibility: hidden;
        opacity: 0;

      }
    }

    &-title {
      color: var(--wipfli-core-black);
      font-size: toRem(24);
      line-height: normal;
      margin: toRem(10) 0 toRem(2);
      text-transform: uppercase;
      transition: color .3s ease-in-out;
      
      @include break-md {
        font-size: toRem(34);
        margin: toRem(30) 0 toRem(15);
      }
    }

    &-description {
      margin-bottom: toRem(14);

      &,
      * {
        color: var(--wipfli-core-black);
        font-size: toRem(14);
        line-height: 1.57;

        @include break-md {
          font-size: toRem(18);
          line-height: 1.44;
        }
      }

      @include break-md {
        margin-bottom: toRem(28);
      }
    }

    &-link {
      align-items: center;
      border-bottom: none;
      display: inline-flex;
      font-size: toRem(14);
      font-weight: 600;
      line-height: normal;
      position: relative;
      text-decoration: none;

      svg {
        margin-left: toRem(4);
        height: toRem(13);
        width: toRem(13);

        @include break-md {
          height: toRem(18);
          width: toRem(18);
        }
      }

      &:any-link,
      &:hover {
        border-bottom: none;
        text-decoration: none;
      }

      &:hover {
        span {
          text-decoration: underline;
        }
      }

      @include break-md {
        font-size: toRem(18);

      }

      &-contain {
        @include break-md {
          margin-top: auto;
        }
      }
    }

    &-clickable-link {
      &:link {
        text-decoration: none;
      }
    }

    &-contain {
      border-top: 2px solid var(--wipfli-core-black);
      padding: toRem(8) 0 toRem(20);
      position: relative;

      &:hover {
        .tile-list__item-title{
          color: var(--wipfli-blue);
        }
        .tile-list__item-image-contain {
          &::after {
            opacity: 1;
            visibility: visible;
          }
          img{
            transform: scale(1.15);
            transition: all .3s ease-in-out;
          }
        }
      }

      @include break-md {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: toRem(25) 0 toRem(69);
      }
    }
  }
}