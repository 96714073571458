@import '../../scss/common';

.eventlisting {
  padding: toRem(14) 0 toRem(55);

  &__container {
    padding-inline: 28px;
    @include container-width;

    @include break-md {
      padding: 0;
    }
  }

  &__number {
    color: var(--wipfli-black);
    font-family: var(--secondary-font-family);
    font-size: toRem(14);
    font-weight: normal;
    line-height: 1.86;
    margin-bottom: 16px;
    text-align: center;

    @include break-md {
      font-size: toRem(18);
      line-height: 1.44;
      margin-bottom: 22px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    padding: 0;

    &-item {
      width: 100%;

      &-content {
        padding-bottom: toRem(16);

        @include break-md {
          height: 100%;
          padding-bottom: 60px;
        }
      }

      &-link {
        border-top: 2px solid var(--wipfli-core-black);
        padding-top: toRem(16);

        @include break-md {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
      }

      &-date {
        color: var(--wipfli-black);
        font-family: var(--secondary-font-family);
        font-size: toRem(14);
        font-weight: normal;
        line-height: 1.86;

        @include break-md {
          font-size: toRem(18);
          line-height: 1.44;
        }
      }

      &-title {
        line-height: 0;

        a {
          color: var(--wipfli-core-black);
          font-size: toRem(34);
          font-family: var(--title-font-family);
          line-height: 1.06;
          text-transform: uppercase;
          margin-bottom: toRem(20);
          text-decoration: none;

          @include break-md {
            font-size: toRem(48);
            line-height: 1.04;
            margin-bottom: toRem(19);
          }

          &:any-link {
            color: var(--wipfli-core-black);
            transition: color 0.3s ease-in-out;
            text-decoration: none;
            border: none;
            &:hover {
              color: var(--wipfli-blue);
            }
          }
        }
      }

      &-innercontent {
        margin-bottom: toRem(18);

        &,
        * {
          color: var(--wipfli-black);
          font-family: var(--primary-font-family);
          font-size: toRem(14);
          font-weight: normal;
          line-height: 1.57;

          @include break-md {
            font-size: toRem(18);
            line-height: 1.44;
          }
        }
      }

      &-outercontent {
        @include break-md {
          margin-top: auto;
        }
      }

      &-links {
        color: var(--wipfli-blue);
        font-size: 0.875rem;
        font-weight: 600;
        line-height: normal;
        display: flex;
        align-items: center;

        &:any-link {
          text-decoration: none;
          border-bottom: none;

          span {
            text-decoration: underline;
            border-bottom: 1.5px solid transparent;
          }

          &:hover {
            span {
              border-bottom: 1.5px solid var(--wipfli-blue);
            }
          }
        }

        svg {
          margin-left: 0.25rem;
          height: 0.8125rem;
          width: 0.8125rem;
          vertical-align: middle;
          margin-top: 1px;
        }

        @include break-md {
          font-size: 1.125rem;
        }

        &-contain {
          margin-bottom: toRem(50);
        }
      }

      &-link-list {
        border-top: 1px solid #d0d3d4;
        padding: 0;
        margin: 0;
        padding-top: toRem(10);

        list-style: none;
        display: flex;
        flex-wrap: wrap;

        li {
          &:not(:first-child) {
            &::before {
              content: ',';
              margin-right: 3px;
            }
          }
        }

        &,
        * {
          color: var(--wipfli-black);
          font-family: var(--secondary-font-family);
          font-size: toRem(12);
          line-height: 1.67;

          @include break-md {
            font-size: toRem(15);
          }
        }
      }

      @include break-md {
        padding-left: toRem(14);
        padding-right: toRem(14);
        width: 50%;
      }
    }

    @include break-md {
      margin-left: toRem(-14);
      margin-right: toRem(-14);
    }
  }

  @include break-md {
    padding: toRem(60) 0 toRem(90);
  }
}