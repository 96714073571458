@import '../../scss/common';

.pagination {
  color: black;
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  margin: 0 auto;
  padding: toRem(55) 0 toRem(50);
  position: relative;

  button {
    align-items: center;
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    display: flex;
    font-weight: inherit;
    height: 100%;
    padding: 0;
    justify-content: center;
    text-decoration: none;
    width: 100%;

    &:disabled {
      cursor: no-drop;
      pointer-events: none;
      color: var(--wipfli-blue-50);
      border: 1.8px solid var(--wipfli-blue-50);
      svg {
        path {
          stroke: var(--wipfli-blue-50);
        }
      }
    }
  }

  &__list {
    position: absolute;
    bottom: 0;
    align-items: center;
    display: flex;
    gap: 1.8rem;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: center;
    width: 100%;
    z-index: 1;
    &__item {
      align-items: center;
      display: flex;
      justify-content: center;

      &--page,
      &--separator {
        display: flex;
      }
      &--separator {
        color: var(--wipfli-blue);
      }
      &--page {
        button {
          color: var(--wipfli-blue);
          font-size: toRem(14);
          font-weight: 600;
          line-height: 1.86;
          text-decoration: underline;
          @include break-md {
            font-size: toRem(18);
            line-height: 1.44;
          }
        }
      }
      &--active {
        color: skyblue;
        button {
          color: var(--wipfli-black);
          text-decoration: none;
        }
      }

      &--display {
        display: none;
      }
      &__next,
      &__previous {
        button {
          background: linear-gradient(to left, transparent 50%, var(--wipfli-blue) 28%) 100%;
          background-size: 200%;
          align-items: center;
          border: 1.8px solid var(--wipfli-blue);
          display: flex;
          color: var(--wipfli-blue);
          font-size: toRem(18);
          font-weight: 600;
          padding: 14px 27px 14px 22px;
          height: auto;
          transition: all 0.3s ease-in-out;
          line-height: normal;
          &:hover {
            background-position: 0;
            color: white;
            border-color: var(--wipfli-blue);
            svg {
              path {
                stroke: white;
              }
            }
          }
          svg {
            height: 18px;
            width: 18px;
            path {
              transition: all 0.3s ease-in-out;
            }
          }
        }
      }
      &__previous {
        button {
          svg {
            transform: rotate(180deg);
            margin-right: 8px;
          }
        }
      }
      &__next {
        display: flex;
        justify-content: flex-end;
        button {
          svg {
            margin-left: 8px;
          }
        }
      }
    }
    @include break-md {
      max-width: 730px;
      margin: 0 auto;
      position: static;
    }
  }

  @include break-md {
    &__list {
      &__item {
        &--display {
          display: none;
        }

        &--page,
        &--separator {
          display: flex;
        }
      }
    }
    justify-content: flex-start;
    padding: toRem(60) 0 0;
  }
}
