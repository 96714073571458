@import '../../scss/common';

.search {

  margin-bottom: 4rem;

  @media (max-width: 661px) {
    width: 90% !important;
    margin-bottom: 2rem;
  }

  .search-container-wrapper {
    position: relative;
    margin-bottom: 40px;
  }

  .search_result_suggestion {
    width: 40%;
    position: absolute;
    background-color: #fbfbfb;
    min-width: 250px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;

    li {
      &:hover {
        background-color: #f0f2f3 !important;
      }
    }

    @media (max-width: 661px) {
      width: 100%;

      ul {
        padding: 0 !important;
      }
    }
  }

  &_input {
    margin: 40px 0 0;

    &_wrapper {
      width: 40%;
      display: flex;
      position: relative;
      align-items: center;

      &_textbox {
        border: 2px solid #0050ff;
        width: 100%;
        height: 45px;
        color: #0050ff;
        padding-left: 15px;
        font-weight: 500;

        &:focus {
          border-color: 2px solid #0050ff;
          outline: none !important;
        }

        @media (max-width: 661px) {
          height: 40px;
        }
      }

      svg {
        position: absolute;
        right: 10px;
        width: 30px;
        color: #0050ff;
        cursor: pointer;

        path {
          fill: #0050ff;
        }
      }

      @media (max-width: 661px) {
        width: 100%;
      }
    }

    @media (max-width: 661px) {
      margin: 30px 0 0;
    }
  }

  &_result {

    &_wrapper {
      width: 100%;
      margin-bottom: 6rem;

      @media (max-width: 661px) {
        width: 100%;
      }
    }

    &_info {
      border-bottom: 1px solid var(--wipfli-core-black);
      color: var(--wipfli-core-black);

      &_text {
        font-size: 1.125rem;
        line-height: 1.44;
        font-weight: 500;
      }
    }

    &_list {
      border-top: 1px solid var(--wipfli-core-black);
      list-style-type: none;
      margin: 0;
      padding: 0;

      &_item {
        padding-top: 1.5rem;
        border-bottom: 2px solid var(--wipfli-core-black);
        padding-bottom: 2rem;

        &_eyebrow {
          color: var(--wipfli-blue);
          font-size: 1rem;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
        }

        &_title {
          border: none;
          color: #000;
          color: var(--wipfli-core-black);
          text-decoration: none;
          transition: color .3s ease-in-out;
          font-size: 3rem;
          line-height: 1.04;
          margin-bottom: 1.1875rem;
          text-transform: uppercase;
          cursor: pointer;

          h3 {
            color: var(--wipfli-core-black);
            width: fit-content;

            a {
              color: var(--wipfli-core-black);
              line-height: normal;
              font-size: 2.125rem;
              font-weight: 500;
              font-family: Anton, sans-serif;
              text-decoration: underline 2px !important;
              text-underline-offset: 5px;

              &:hover {
                border-bottom: none;
              }

              @media (max-width: 661px) {
                font-size: 1.688rem;
                text-underline-offset: 2px;
              }
            }
          }
        }

        &_desc {
          font-size: 1.125rem;
          line-height: 1.44;
          color: var(--wipfli-black);
          font-family: Montserrat, sans-serif;
          font-family: var(--primary-font-family);
          font-weight: 500;

          @media (max-width: 661px) {
            font-size: 0.938rem;
          }
        }

        &_link {
          color: #000;
          color: var(--wipfli-core-black);

          &_text {
            color: #000 !important;
            color: var(--wipfli-core-black);
            text-decoration: underline;
            font-size: 12px;
            font-weight: 500;
            line-height: normal;
            font-weight: 600;
            cursor: pointer;

            &:hover {
              border-bottom: none;
            }

            @media (max-width: 661px) {
              font-size: 0.938rem;
            }
          }
        }

        @media (max-width: 661px) {
          padding-bottom: 1.5rem;
        }
      }
    }
  }

  // tablet overrides
  @include break-md {}

  // desktop overrides
  @include break-lg {}
}