@import '../../scss/common';

.articlelisting {
  padding: toRem(14) 0 toRem(55);

  &__container {
    padding-inline: 28px;
    @include container-width;

    @include break-md {
      padding: 0;
    }
  }

  &__number {
    color: var(--wipfli-black);
    font-family: var(--secondary-font-family);
    font-size: toRem(14);
    font-weight: normal;
    line-height: 1.86;
    margin-bottom: 16px;
    text-align: center;

    @include break-md {
      font-size: toRem(18);
      line-height: 1.44;
      margin-bottom: 22px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin-bottom: 0;
    padding: 0;

    &-item {
      width: 100%;

      &-content {
        padding-bottom: toRem(16);

        @include break-md {
          height: 100%;
          padding-bottom: 60px;
        }
      }

      &-link {
        border-top: 2px solid var(--wipfli-core-black);
        padding-top: toRem(16);

        @include break-md {
          display: flex;
          flex-direction: column;
          height: 100%;
        }
      }

      &-date {
        color: var(--wipfli-black);
        font-family: var(--secondary-font-family);
        font-size: toRem(14);
        font-weight: normal;
        line-height: 1.86;

        @include break-md {
          font-size: toRem(18);
          line-height: 1.44;
        }
      }

      &-title {
        line-height: 0;

        a {
          color: var(--wipfli-core-black);
          font-size: toRem(34);
          font-family: var(--title-font-family);
          line-height: 1.06;
          text-transform: uppercase;
          margin-bottom: toRem(20);
          text-decoration: none;

          @include break-md {
            font-size: toRem(48);
            line-height: 1.04;
            margin-bottom: toRem(19);
          }

          &:any-link {
            color: var(--wipfli-core-black);
            transition: color 0.3s ease-in-out;
            text-decoration: none;
            border: none;
            &:hover {
              color: var(--wipfli-blue);
            }
          }
        }
      }

      &-innercontent {
        margin-bottom: toRem(18);

        &,
        * {
          color: var(--wipfli-black);
          font-family: var(--primary-font-family);
          font-size: toRem(14);
          font-weight: normal;
          line-height: 1.57;

          @include break-md {
            font-size: toRem(18);
            line-height: 1.44;
          }
        }
      }

      &-outercontent {
        @include break-md {
          margin-top: auto;
        }
      }

      &-links {
        color: var(--wipfli-blue);
        font-size: 0.875rem;
        font-weight: 600;
        line-height: normal;
        display: flex;
        align-items: center;

        &:any-link {
          text-decoration: none;
          border-bottom: none;

          span {
            text-decoration: underline;
            border-bottom: 1.5px solid transparent;
          }

          &:hover {
            span {
              border-bottom: 1.5px solid var(--wipfli-blue);
            }
          }
        }

        svg {
          margin-left: 0.25rem;
          height: 0.8125rem;
          width: 0.8125rem;
          vertical-align: middle;
          margin-top: 1px;
        }

        @include break-md {
          font-size: 1.125rem;
        }

        &-contain {
          margin-bottom: toRem(50);
        }
      }

      &-link-list {
        border-top: 1px solid #d0d3d4;
        padding: 0;
        margin: 0;
        padding-top: toRem(10);

        list-style: none;
        display: flex;
        flex-wrap: wrap;

        li {
          &:not(:first-child) {
            &::before {
              content: ',';
              margin-right: 3px;
            }
          }
        }

        &,
        * {
          color: var(--wipfli-black);
          font-family: var(--secondary-font-family);
          font-size: toRem(12);
          line-height: 1.67;

          @include break-md {
            font-size: toRem(15);
          }
        }
      }

      @include break-md {
        padding-left: toRem(14);
        padding-right: toRem(14);
        width: 50%;
      }
    }

    @include break-md {
      margin-left: toRem(-14);
      margin-right: toRem(-14);
    }
  }

  @include break-md {
    padding: toRem(60) 0 toRem(90);
  }
}

.filter-by-topic {
  .container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-top: 2px solid var(--wipfli-core-black);
    vertical-align: middle;
    align-items: center;
    padding: 20px 0 0;

    &_title {
      text-transform: uppercase;
      margin-bottom: 0;
    }

    &_btn {
      cursor: pointer;
    }
  }

  .search-result {
    display: none;
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    column-gap: 10px;
    margin-top: 10px;

    @media screen and (max-width: 661px) {
      margin-bottom: 15px;
      gap: 10px;
      display: block;
    }

    &_label {
      font-weight: 500;
      font-size: 12px;
      line-height: 1;
      display: inline-flex;
      gap: 4px;
      color: #0050ff;
      margin-right: 5px;
      text-transform: capitalize;
      pointer-events: none;

      &_chekbox {
        border-color: #0050ff;
        border-radius: 0;
      }
    }
  }
}

.ReactModalPortal {
  .ReactModal__Content {
    border: none !important;
    background: none !important;
  }
}

.ReactModal__Content--after-open {
  --shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;

  @media screen and (max-width: 768px) {
    padding: 0 !important;
    margin-top: 80px;
    overflow: hidden !important;
    width: 100%;
    inset: 0 !important;
  }
}

.resource-hub-industry-service-filter-v2 {
  .filter-data {
    position: absolute;
    background: rgba(255, 255, 255, 1);
    max-width: 1100px;
    width: -webkit-fill-available;
    padding: 40px 40px 55px 40px;
    box-shadow: 0 0 24px rgba(20, 30, 43, 0.08) !important;
    z-index: 1;
    display: none;
    margin: auto;
    left: 0;
    right: 0;
    top: 24%;

    @media screen and (max-width: 661px) {
      padding: 10px 10px 30px 10px !important;
    }

    .acc-header,
    .acc-header-child {
      display: none;
    }

    &.show {
      display: block;
    }

    .row {
      &.row .filter-details {
        max-height: calc(100vh - 220px);
        overflow-y: auto;
      }
    }

    .filter-header {
      padding-bottom: 24px;
      margin-right: -15px;

      .clear-filter {
        font-family: Montserrat, sans-serif;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: rgba(0, 80, 255, 1);
        font-weight: 600 !important;
        font-style: normal !important;
        text-decoration: none;
        cursor: pointer;

        &.disabled {
          color: rgba(227, 228, 228, 1);
          pointer-events: none;
        }
      }

      .btn-apply-filter {
        padding: 9px 16px;
        font-family: Montserrat, sans-serif;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-align: left;
        color: rgba(0, 80, 255, 1);
        background: none;
        border: 1px solid rgba(0, 80, 255, 1);
        float: right;
        font-weight: 600 !important;
        font-style: normal !important;

        &.disabled {
          color: rgba(227, 228, 228, 1);
          border: 1px solid rgba(227, 228, 228, 1);
          pointer-events: none;
        }
      }
    }
  }

  @media screen and (max-width: 661px) {
    .container_btn {
      padding: 20px 0 0 !important;
    }

    .acc-details {
      ul {
        padding-left: 0;
      }
    }

    .filter-by-topic .container_btn {
      padding: 10px 0 0 !important;
    }
  }

  @media screen and (max-width: 768px) {
    .filter-data.show {
      padding: 10px;
    }

    .acc-details {
      ul {
        padding-left: 0;
      }
    }
  }

  .menu-col {
    background: rgba(242, 243, 243, 1);
    padding: 40px;

    .sub-menu-list {
      padding-inline: 32px;
    }

    ul {
      list-style: none;
      margin: 0px;

      li {
        .custom-control-label {
          font-family: Montserrat, sans-serif;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.5px;
          text-align: left;
          color: rgba(0, 0, 0, 1);

          .active {
            font-family: Montserrat, sans-serif;
          }
        }
      }
    }

    .filter-label {
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-align: left;
      color: rgba(0, 0, 0, 1);
      padding-right: 8px;
      font-weight: 600 !important;
      font-style: normal !important;
    }

    .filter-count {
      font-family: Montserrat, sans-serif;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-align: left;
      color: rgba(0, 80, 255, 1);
    }
  }

  .custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }

  .custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 1;
  }

  .custom-control-label {
    font-family: Montserrat, sans-serif;
    font-weight: 400 !important;
    font-style: normal !important;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #000;
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
  }
}

@media (max-width: 1199px) {
  .resource-hub-industry-service-filter-v2 {
    width: 100%;

    .filter-data {
      position: static;
      padding: 0px;
      display: none;
      padding-bottom: 32px;
      width: 100%;

      .acc-header,
      .acc-header-child {
        display: block;

        &.active {
          svg {
            transform: rotate(183deg);
          }
        }
      }

      &.show {
        display: block;
      }

      .acc-details,
      .acc-details-child {
        display: none;

        &.show {
          display: block;
        }
      }

      .filter-header {
        display: flex;
        align-items: center;
        padding: 24px 0 24px;
        margin-bottom: 0;
        margin-right: 0px;

        > .col-md-6 {
          width: 50%;
        }

        .clear-filter {
          font-family: Montserrat, sans-serif;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0px;
          text-align: left;
        }

        .btn-apply-filter {
          font-family: Montserrat, sans-serif;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: left;
        }
      }

      .col-industry {
        padding-bottom: 8px;
      }

      .col-industry,
      .col-service {
        flex: 0 0 100%;
        max-width: 100%;
      }

      .menu-col {
        background: rgba(255, 255, 255, 1);
        padding: 0px;

        ul {
          li {
            margin: 12px 0px;
          }
        }

        .acc-header,
        .acc-header-child {
          font-family: Montserrat, sans-serif;
          border: none;
          color: rgba(0, 80, 255, 1);
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.5px;
          text-transform: uppercase;
          width: calc(100% - 40px);
          text-align: left;
          display: inline;
          padding: 0px;
          float: right;
          position: absolute;
          z-index: 5;
          left: 40px;
          padding-left: calc(100% - 90px);
        }
      }
    }
  }
}
