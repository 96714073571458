@import '../../scss/common';

.featured-content {
  display: flex;
  &-container {
    width: 100%;
    max-width: 1110px;
    margin: 0 auto;
    padding: 51px 28px 60px 26px;

    @include break-md {
      padding: 86px 20px 82px 20px;
    }
    @include break-xl {
      padding-left: 0;
      padding-right: 0;
    }
  }
  &__mobile_link {
    display: block;
    @include break-lg {
      display: none;
    }
  }
  &__desktop_link {
    display: none;
    @include break-lg {
      display: block;
    }
  }
  &__inner-contain {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 41px;
    @include break-lg {
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 0px;
    }
    &-left {
      @include break-lg {
        width: 45%;
      }
      &-pretext {
        color: var(--wipfli-black);
        text-transform: uppercase;
        font-weight: normal;
        @include break-md {
          line-height: 1.06;
          margin-bottom: 23px;
        }
      }
      &-title {
        color: var(--wipfli-blue);
        line-height: 1;
        margin-bottom: 39px;
        text-transform: uppercase;
        @include break-md {
          margin-bottom: 46px;
        }
      }
      &-description {
        color: var(--wipfli-black);
        font-size: 14px;
        line-height: 1.57;
        @include break-md {
          font-size: 18px;
          line-height: 1.44;
        }
        @include break-lg {
          margin-bottom: 26px;
        }
      }
    }
    &-right {
      width: 100%;
      @include break-lg {
        width: 50%;
      }
      img {
        width: 100%;
        object-fit: contain;
      }
      &-thumbnail {
        border: none;
        cursor: pointer;
        position: relative;
        width: 100%;
        svg {
          position: absolute;
          top: 50%;
          height: 18px;
          width: 18px;
          transform: translateY(-50%);
          @include break-md {
            height: 35px;
            width: 35px;
          }
        }
      }
      .swiper {
        height: 100%;
        padding-bottom: 40px;
        @include break-md {
          padding-bottom: 60px;
        }
        @include break-lg {
          padding: 0;
          margin-top: 132px;
        }
      }
      .swiper-pagination {
        display: flex;
        justify-content: center;
        bottom: 0 !important;
        left: 0;
        right: 0;
        height: fit-content;
        width: auto;
        @include break-lg {
          top: 30%;
          gap: 8px;
        }
        .swiper-pagination-bullet {
          display: block;
          background: transparent;
          height: auto;
          width: auto;
          opacity: 1;
          padding: 0 8px;
          .swiper-pagination-button {
            display: block;
            border-radius: 50%;
            border: none;
            background-color: var(--wipfli-suva-gray);
            height: 8px;
            width: 8px;

            @include break-md {
              height: 12px;
              width: 12px;
            }
          }
          &.swiper-pagination-bullet-active {
            .swiper-pagination-button {
              background-color: var(--wipfli-blue);
            }
          }
        }
      }
      &-icons {
        width: 91px;
        height: 52px;
        object-fit: contain;
        opacity: 0.71;
        mix-blend-mode: luminosity;
        filter: grayscale(1);
        @include break-md {
          max-width: 134px;
          max-height: 77px;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  &__dark-theme {
    background-color: var(--wipfli-black);
    .btn-black,
    .btn-white {
      background: linear-gradient(to left, transparent 50%, var(--wipfli-blue) 50%) right;
      background-size: 200%;
      transition: 0.25s ease-out;
      border: 2px solid var(--wipfli-blue);
      color: var(--wipfli-white);
      &::after {
        background-image: url('../../../public/local/Home/Project/demo-site/demo-site/white.svg');
        background-repeat: no-repeat;
        background-size: contain;
      }
      &:hover {
        background-position: left;
        color: var(--wipfli-white);
        background-color: transparent;
        &::after {
          background-image: url('../../../public/local/Home/Project/demo-site/demo-site/white.svg');
          background-repeat: no-repeat;
          background-size: contain;
        }
      }

      &:active {
        background-position: left;
        border: 2px solid var(--wipfli-white);
        background-color: var(--wipfli-white);
      }
    }
    .featured-content__inner-contain-left-pretext {
      color: var(--wipfli-white);
    }
    .featured-content__inner-contain-left-description {
      color: var(--wipfli-white);
    }
  }
  &.featured-content__right-align {
    .featured-content__inner-contain-right {
      order: 1;
    }
    .featured-content__inner-contain-left {
      order: 2;
    }
  }
  &__video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;

    video {
      object-fit: cover;
    }
    &-modal {
      background-color: rgba($color: #63666a, $alpha: 0.8);
      position: relative;
      max-width: toRem(494);
      margin: 0 auto;
      transform: scale(0);
      transition: transform 0.4s ease-in-out;
      width: 100%;

      @include break-md {
        max-width: toRem(624);
      }

      @include break-xl {
        margin-top: toRem(20);
        max-width: toRem(1110);
      }

      &-closebtn {
        background-color: transparent;
        border: none;
        position: absolute;
        height: toRem(20);
        width: toRem(21);
        right: 0;
        top: toRem(-30);
        z-index: 1;

        &::after,
        &::before {
          position: absolute;
          left: toRem(8);
          content: ' ';
          height: toRem(16);
          width: toRem(2);
          background-color: var(--wipfli-white);
          top: 0;
        }

        &::after {
          transform: rotate(-45deg);
        }

        &::before {
          transform: rotate(45deg);
        }

        &:focus {
          outline: none;
          border: toRem(2) dotted var(--wipfli-white);
        }
      }

      &-contain {
        position: relative;
        padding-top: 56.25%;
        height: 0;
      }

      &-container {
        align-items: center;
        background-color: rgba($color: #000000, $alpha: 0.6);
        display: flex;
        justify-content: center;
        inset: 0;
        padding: 0 toRem(20);
        position: fixed;
        pointer-events: none;
        opacity: 0;
        visibility: hidden;

        transition: all 0.2s ease-in-out;

        &.active {
          opacity: 1;
          visibility: visible;
          pointer-events: all;
          z-index: 99;

          .featured-content__video-modal {
            transform: scale(1);
          }
        }
      }
    }
  }
}
