@import "../../scss/common";

.testimonial {
  background-color: var(--wipfli-blue);
  width: 100%;
  &-container {
    width: 100%;
    max-width: 1110px;
    margin: 0 auto;
    text-align: center;
    padding: 52px 28px 54px 26px;
    @include break-md {
      padding: 81px 20px 86px 20px;
    }
    @include break-xl {
     padding-left: 0;
     padding-right: 0;
    }
  }
  &__quote {
    color: var(--wipfli-white);
    font-size: 20px;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: 0px;
    margin: 0;
    margin-bottom: 31px;
    i{
      font-style: italic;
    }
    @include break-md {
      font-size: 34px;
      font-weight: 500;
      line-height: 1.35;
      letter-spacing: normal;
      margin-bottom: 46px;
    }
  }
  &__author {
    color: var(--wipfli-white);
    font-size: 12px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 0px;
    margin-bottom: 0px;
    @include break-md {
      font-size: 18px;
      line-height: 1.44;
      margin-bottom: 8px;
    }
  }
  &__role {
    color: var(--wipfli-white);
    color: var(--wipfli-white);
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0px;
    margin: 0;
    @include break-md {
      font-size: 18px;
      line-height: 1.44;
    }
  }
  &__organisation {
    color: var(--wipfli-white);
    color: var(--wipfli-white);
    font-size: 12px;
    line-height: 1.5;
    letter-spacing: 0px;
    margin: 0;
    @include break-md {
      font-size: 18px;
      line-height: 1.44;
    }
  }
}

[data-animation-enable = "true" ] {
  &.testimonial-fadein {
    .testimonial__quote {
      animation: fadeIn 1s ease-in-out ;
    }
    .testimonial__author {
      animation: fadeIn 2s ease-in-out ;
    }
    .testimonial__role {
      animation: fadeIn 2s ease-in-out ;
    }
    .testimonial__organisation {
      animation: fadeIn 2s ease-in-out ;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
