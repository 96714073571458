@import '../../scss/common';

.article-carousel {
  padding: toRem(57) toRem(20) toRem(60);

  &__container {
    @include container-width;
    position: relative;
  }

  &__title {
    color: var(--wipfli-core-black);
    font-weight: normal;
    text-transform: uppercase;
  }

  &__inner-contain {
    display: flex;
    justify-content: space-between;
  }

  &__btn-contain {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    padding-left: 15px;
  }

  &__swipper {
    margin-top: toRem(27);

    .swiper-slide {
      opacity: 1;
      height: auto;
      transition: opacity 0.3s ease-in-out;
    }

    &-content-link {

      &:any-link {
        border-bottom: none;
        text-decoration: none;
      }

      @include break-md {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
    }

    &-content {
      border-left: 2.1px solid var(--wipfli-blue);
      padding: toRem(14) 0 toRem(13) toRem(14);
      height: 100%;

      @include break-md {
        border-left: 2.5px solid var(--wipfli-blue);
        padding: toRem(24) 0 toRem(23) toRem(23);
      }

      @include break-xl {
        opacity: 1;
        transition: opacity 0.3s ease-in-out;

        &-noactive {
          opacity: 0.7;
        }
      }
    }

    &-description {

      &,
      * {
        color: var(--wipfli-core-black);
        font-size: toRem(14);
        line-height: 1.57;

        @include break-md {
          font-size: toRem(18);
          line-height: 1.44;
        }
      }

      @include break-md {
        margin-top: auto;
      }
    }

    &-date {

      &,
      * {
        color: var(--wipfli-blue);
        font-family: var(--secondary-font-family);
        font-size: toRem(14);
        line-height: 1.58;
        margin-bottom: toRem(11);

        @include break-md {
          font-size: toRem(18);
          line-height: 1.44;
          margin-bottom: toRem(9);
        }
      }
    }

    &-btn-next,
    &-btn-prev {
      background-color: transparent;
      align-items: center;
      border: none;
      display: flex;
      justify-content: center;
      z-index: 9;

      &:hover {


        svg {
          background-color: var(--wipfli-blue);

          path {
            stroke: var(--wipfli-white);
          }
        }
      }

      svg {
        border-radius: 50%;
        height: toRem(25);
        width: toRem(25);

        @include break-md {
          height: toRem(35);
          width: toRem(35);
        }
      }

      &.swiper-button-disabled {
        opacity: 0.7;
        pointer-events: none;
        svg{
          border-color: #80A7FF;
          background-color: #80A7FF;
          g{
            stroke: #80A7FF;
          }
          path {
            stroke: var(--wipfli-white);
          }
        }
      }

      &.swiper-button-lock {
        visibility: hidden;
      }
    }

    &-btn-prev {
      margin-right: 10px;

      @include break-md {
        margin-right: 18px;
      }
    }

    &-title {
      color: var(--wipfli-blue);
      font-family: var(--title-font-family);
      font-size: toRem(34);
      line-height: 1.06;
      margin-bottom: toRem(53);
      text-transform: uppercase;
      transition: color 0.3s ease-in-out;

      @include break-md {
        font-size: toRem(48);
        line-height: 1.04;
        margin-bottom: toRem(55);
      }
    }

    &-link {
      align-items: center;
      color: var(--wipfli-blue);
      display: flex;
      font-size: toRem(14);
      font-weight: 600;
      line-height: normal;

      &:hover {
        span {
          text-decoration: underline;
        }
      }

      svg {
        display: block;
        margin-left: toRem(4);
        height: toRem(13);
        width: toRem(13);

        @include break-md {
          height: toRem(21);
          width: toRem(21);
        }
      }

      &-contain {
        margin-top: toRem(12);

        @include break-md {
          margin-top: toRem(20);
        }
      }

      @include break-md {
        font-size: toRem(18);
      }
    }

    @include break-xl {
      &:hover {

        .swiper-slide {
          opacity: 0.7;

          .article-carousel__swipper-title {
            color: #80A7FF;
          }

          &:hover {
            opacity: 1;

            .article-carousel__swipper-title {
              color: var(--wipfli-blue);
              ;
            }
          }
        }
      }
    }
  }

  &__link-contain {
    margin-top: toRem(30);

    @include break-md {
      margin-top: toRem(41);
    }
  }

  @include break-md {
    padding: toRem(57) toRem(15) toRem(60);
  }

  @include break-xl {
    padding: toRem(89) 0 toRem(89);
  }
}