// Boostrap Variable overrides
// see full list of Boostrap variables here:
// https://github.com/twbs/bootstrap/blob/v5.1.3/scss/_variables.scss

$container-max-widths: (
  sm: 540px,
  md: 768px,
  lg: 960px,
  xl: 1200px,
  xxl: 1201px,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,

  // iPad Mini tablet starts at 744px, a Samsung tablet was 755px (something like that)
  // Must be in sync with Constants.CSS.GridMdMin
  md: 744px,

  // Must be in sync with Constants.CSS.GridLgMin
  lg: 992px,

  // Must be in sync with Constants.CSS.GridXlMin
  xl: 1200px,

  xxl: 1400px,
);

$grid-gutter-width: 1.6rem;
