@import "../../scss/common";

.footer {
  background-color: black;
  width: 100%;

  &-container {
    margin: 0 auto;
    width: 100%;
    padding-left: 27px;
    padding-top: 59px;
    padding-bottom: 57px;

    @include break-md {
      padding-left: 27px;
      padding-top: 59px;
      padding-bottom: 57px;
    }

    @include break-lg {
      max-width: 1110px;
      padding-top: 57px;
      padding-bottom: 37px;
      padding-left: 27px;
    }

    @include break-xl {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__logo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    @include break-lg {
      margin-bottom: 30px;
    }

    &-title {
      color: var(--wipfli-white);
      text-transform: uppercase;
      font-size: 30px;
      font-weight: normal;
      line-height: 1.07;
      text-align: left;
      width: 50%;

      @include break-md {
        font-size: 48px;
        text-transform: uppercase;
        letter-spacing: normal;
        line-height: 50px;
        width: fit-content;
      }
    }

    &-link {
      width: 50%;

      @include break-md {
        width: fit-content;
      }

      &:any-link,
      :hover {
        text-decoration: none;
        border: none;
      }

      &-img {
        width: 100%;
        height: auto !important;

        @include break-md {
          max-width: 290px;
          max-height: 63px;

        }
      }
    }
  }

  &__menu {
    margin-bottom: 56px;

    @include break-lg {
      margin-bottom: 38px;
      width: 65%;
    }

    &-items {
      margin: 0;
      padding: 0;
      list-style-type: none;
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      &-lists {
        width: 50%;

        @include break-md {
          margin-right: 0px;
          margin-bottom: 0px;
          width: 33%;
        }

        &-links {
          color: var(--wipfli-white);
          text-decoration: none;
          font-size: 14px;
          font-weight: 500;
          line-height: 2.29;
          letter-spacing: normal;

          &:hover {
            color: var(--wipfli-white);
            text-decoration: none;
            border: none;
            border-bottom: 1.5px solid var(--wipfli-white);
            transition: all 0.2s ease-in-out;
          }

          &:any-link {
            text-decoration: none;
            color: var(--wipfli-white);
          }

          @include break-md {
            font-size: 16px;
            line-height: 2;
          }
        }
      }
    }
  }

  &__copy {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;

    div {
      display: flex;
      align-items: center;

      div,
      p {
        color: var(--wipfli-white);
        font-size: 10px;
        font-weight: normal;
        line-height: 1.9;
        letter-spacing: normal;
        margin: 0;
        padding-right: 10px;

        @include break-md {
          font-size: 12px;
          line-height: 0.83;
        }
      }
    }

    a {
      color: var(--wipfli-white);
      font-size: 10px;
      font-weight: normal;
      line-height: 1.9;
      letter-spacing: normal;
      padding-right: 10px;
      padding-left: 10px;
      position: relative;
      text-decoration: none;

      @include break-md {
        font-size: 12px;
        line-height: 0.83;
      }

      &::after {
        content: "|";
        position: absolute;
        left: -2px;
      }

      &:any-link,
      :hover {
        text-decoration: none;
        border: none;
        color: var(--wipfli-white);
      }
    }
  }
}