@import "../../scss/common";

.error-block {
  padding: 30px 0;

  &-svg-container {
    svg {
      fill: var(--wipfli-blue);
      circle{
        fill: var(--wipfli-blue);
      }
      path{
        fill: var(--wipfli-blue);
      }
      polygon{
        fill: var(--wipfli-blue);
      }
    }

    .st0 {
      fill: var(--color-primary);
    }

    .st1 {
      opacity: 0.2;
    }

    .st3 {
      fill: var(--wipfli-blue);
    }

    .st5 {
      fill: #e0e0e0;
    }

    .st6 {
      fill: #bdbdbd;
    }

    .st7 {
      opacity: 0.64;
    }

    .st8 {
      fill: #f5f5f5;
    }

    #loupe {
      animation: bounce-in-up 2s ease forwards;
    }

    #ombre-loupe {
      animation: fade-in 2s 3s ease forwards;
    }

    #x-gauche,
    #x-droite,
    #ombre-loupe,
    #decorations {
      opacity: 0;
    }

    #x-gauche,
    #x-droite {
      animation: fade-in 2s ease forwards;
    }

    #x-gauche {
      animation-delay: 1s;
    }

    #x-droite {
      animation-delay: 2s;
    }

    #decorations {
      transform-origin: center;
      animation: fade-in 2s ease forwards, rotate-grow 50s linear infinite alternate;
      animation-delay: 4s;
    }

    @keyframes rotate-grow {
      from {
        transform: rotate(0) scale(0.8);
        transform: rotate(0) scale(0.8);
      }

      to {
        transform: rotate(360deg) scale(1.5);
        transform: rotate(360deg) scale(1.5);
      }
    }

    @keyframes rotate-grow {
      from {
        transform: rotate(0) scale(0.8);
        transform: rotate(0) scale(0.8);
      }

      to {
        transform: rotate(360deg) scale(1.5);
        transform: rotate(360deg) scale(1.5);
      }
    }

    @keyframes fade-in-left {
      from {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
      }

      to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
    }

    @keyframes fade-in-right {
      from {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
      }

      to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
    }

    @keyframes fade-in-right {
      from {
        opacity: 0;
        transform: translate3d(100%, 0, 0);
        transform: translate3d(100%, 0, 0);
      }

      to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
    }

    @keyframes fade-in-left {
      from {
        opacity: 0;
        transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
      }

      to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
    }

    @keyframes fade-in {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    @keyframes fade-in {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }

    @keyframes bounce-in-up {
      from,
      60%,
      75%,
      90%,
      to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      from {
        opacity: 0;
        transform: translate3d(0, 3000px, 0);
        transform: translate3d(0, 3000px, 0);
      }

      60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
      }

      75% {
        transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0);
      }

      90% {
        transform: translate3d(0, -5px, 0);
        transform: translate3d(0, -5px, 0);
      }

      to {
        transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
    }

    @keyframes bounce-in-up {
      from,
      60%,
      75%,
      90%,
      to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }

      from {
        opacity: 0;
        transform: translate3d(0, 3000px, 0);
        transform: translate3d(0, 3000px, 0);
      }

      60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
      }

      75% {
        transform: translate3d(0, 10px, 0);
        transform: translate3d(0, 10px, 0);
      }

      90% {
        transform: translate3d(0, -5px, 0);
        transform: translate3d(0, -5px, 0);
      }

      to {
        transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
      }
    }

    .bounce-in-up {
      animation-name: bounce-in-up;
      animation-name: bounce-in-up;
    }

    @include break-md {
      display: block;
      max-width: 700px;
      margin: 0 auto 20px;
    }
  }
  &-text-container {
    color: var(--wipfli-black);
    text-align: center;
    @include break-md {
      font-size: 30px;
    }
  }

  @include break-md {
    padding: 60px 0;
  }
}
