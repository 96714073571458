@import "../../scss/common";

.accordion {
  &-container {
    width: 100%;
    max-width: 1110px;
    margin: 0 auto;
    padding: 52px 28px 40px 28px;
    @include break-md {
      padding: 90px 20px;
    }
    @include break-xl {
      padding-left: 0;
      padding-right: 0;
    }
    a {
      margin-top: 38px;
      @include break-md {
        margin-top: 60px;
      }
    }
  }
  &__title {
    text-transform: uppercase;
    font-weight: normal;
    @include break-md {
      font-size: 2.125rem;
      line-height: 2.25rem;
    }
  }
  &__description {
    font-weight: normal;
    margin-bottom: 24px;
    @include break-md {
      font-size: 18px;
      line-height: 1.44;
      width: 100%;
      max-width: 730px;
      margin-top: 23px;
      margin-bottom: 55px;
    }
  }
  &__items {
    margin: 0;
    padding: 0;
    list-style-type: none;
    cursor: pointer;
    &-lists {
      padding-block: 13px;
      border-top: 2.3px solid var(--wipfli-blue);
      border-bottom: 2.3px solid var(--wipfli-blue);
      background-color: var(--wipfli-white);
      margin: -2px 0;
      z-index: 7;
      position: relative;
      overflow: hidden;
      @include break-md {
        padding-block: 28px;
      }
      &.blurr{
          border-top: 2.3px solid var(--wipfli-blue-50);
          border-bottom: 2.3px solid var(--wipfli-blue-50);
          z-index: 6;
          position: relative;
      }
    }
    &-contain {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: none;
      width: 100%;
      background: transparent;
      outline: none;
      &-title {
        color: var(--wipfli-blue);
        font-weight: normal;
        text-transform: uppercase;
        margin: 0;
        text-align: left;
        @include break-md {
          font-size: 48px;
          line-height: 1.04;
        }
        &.blurr{
          color: var(--wipfli-blue-50);
      }
      }
      &-content {
        max-height: 0px;
        overflow: hidden;
        transition: max-height .5s ease-in-out;
      }
      &-content-show {
        height: auto;
        max-height: 300px;
        transition: max-height .5s ease-in-out;
      }
      &-icon {
        &.blurr{
          svg{
            g{
          stroke: var(--wipfli-blue-50);
            }
          }
      }
        &-expand {
          display: block;
          height: 25px;
          width: 25px;
          @include break-md {
            height: 28px;
            width: 28px;
          }
        }
        &-collapse {
          display: block;
          height: 25px;
          width: 25px;
          @include break-md {
            height: 28px;
            width: 28px;
          }
        }
      }
      &-desc {
        color: var(--wipfli-blue);
        font-weight: 500;
        width: 100%;
        max-width: 319px;
        margin: 0;
        padding-top: 16px;
        @include break-md {
          max-width: 1015px;
          font-size: 22px;
          line-height: 1.36;
          padding-top: 20px;

          div,p,span {
            font-size: 22px;
          }
        }
        &-link {
          font-weight: 600;
          margin-left: 10px;
          &:any-link {
            text-decoration: underline;
            text-decoration-thickness: 1px;
          }
          &:hover {
            border-bottom: 2px solid var(--wipfli-blue);
          }
        }
      }
    }
    &:hover {
      @include break-xl {
        .accordion__items-lists {
          border-top: 2.3px solid var(--wipfli-blue-50);
          border-bottom: 2.3px solid var(--wipfli-blue-50);
          transition: border 0.4s ease-in-out;
          h3 {
            color: var(--wipfli-blue-50);
            transition: all 0.4s ease-in-out;
          }
          svg {
            g {
              stroke: var(--wipfli-blue-50);
            }
          }
          &:hover {
            border-top: 2.3px solid var(--wipfli-blue);
            border-bottom: 2.3px solid var(--wipfli-blue);
            transition: border 0.4s ease-in-out;
            position: relative;
            z-index: 8;
            h3 {
              color: var(--wipfli-blue);
              transition: all 0.4s ease-in-out;
            }
            svg {
              g {
                stroke: var(--wipfli-blue);
              }
            }
          }
        }
      }
    }
  }
}
