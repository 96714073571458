@import '../../scss/common';

.ebook-modal {
  max-width: toRem(1110);
  margin: 0 auto;
  width: 100%;
  position: relative;

  &__close-btn {
    background-color: transparent;
    border: none;
    position: absolute;
    height: toRem(10);
    width: toRem(10);
    right: 0;
    top: toRem(-19);
    z-index: 1;

    svg {
      width: 100%;
      display: block;
      height: 100%;
    }

    /* &::after,
    &::before {
      position: absolute;
      left: toRem(14);
      content: " ";
      height: toRem(16);
      width: toRem(2);
      background-color: var(--wipfli-white);
      top: 0;
    }

    &::after {
      transform: rotate(-45deg);
    }

    &::before {
      transform: rotate(45deg);
    }*/

    @include break-md {
      top: toRem(-30);
    }

    @include break-xl {
      height: toRem(20);
      width: toRem(21);

    }
  }

  &-inner-contain {
    background-color: white;
    padding: toRem(20);
    height: 566px;
    overflow-y: auto;
    height: auto;
    @media screen and (orientation:landscape) and (min-device-width: 519px) and (max-device-width: 992px) {

      height: 350px;
    }

    @include break-md {
      padding: toRem(29) toRem(30) toRem(25);
    }

    @include break-lg {
      padding: toRem(58) toRem(60) toRem(51);
    }

    @include break-xl {
      &::-webkit-scrollbar {
        width: 18px;
        height: 12px;
        -webkit-appearance: none;
      }

      &::-webkit-scrollbar-corner {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba($color: #0050ff, $alpha: 0.4);
        border: 6px solid transparent;
        border-radius: 9px;
        background-clip: content-box;
      }

      &::-webkit-scrollbar-track-piece {
        background: white;
      }
    }
  }

  &-contain {
    background-color: rgba($color: #000000, $alpha: 0.8);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    inset: 0;
    padding: 0 toRem(20);
    transition: opacity 0.3s, visibility 0.3s ease-in-out;

    &.show-ebook-modal {
      visibility: visible;
      opacity: 1;
      z-index: 9999;
    }

    &.editing {
      visibility: visible;
      opacity: 1;
      z-index: 99;
      position: relative;
    }

    @media screen and (orientation:landscape) and (min-device-width: 519px) and (max-device-width: 992px) {
      padding: 30px 1.25rem;
    }

    @include break-md {
      align-items: flex-start;
      padding: toRem(121) toRem(20);
    }

    @include break-lg {
      padding: toRem(90) toRem(20);
    }
  }

  @include break-md {
    align-items: flex-start;
  }
}