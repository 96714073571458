@font-face {
  font-family: Anton;
  src: url(../../public/assets/fonts/Anton-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
/*@font-face {
  font-family: Lora;
  src: url(../../public/assets/fonts/Lora-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: Lora;
  src: url(../../public/assets/fonts/Lora-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: Montserrat;
  src: url(../../public/assets/fonts/Montserrat-Regular.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: Montserrat;
  src: url(../../public/assets/fonts/Montserrat-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}*/

@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400&family=Montserrat:wght@400;500;600;700;800;900&display=swap');


h1,
h2,
h3,
h4{
  font-family: Anton, sans-serif;
}

h5,
.eyebrow{
  font-family: 'Lora', serif;
}

body,
html,
p,
a,
small,
li,
strong {
  font-family: 'Montserrat', sans-serif;
}

button, input, optgroup, select, textarea {
  font: inherit;
}
