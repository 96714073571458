@import '../../scss/common';

.article-text {

  h2
   {  
    color: var(--wipfli-blue);   
    font-family: var(--title-font-family);
    font-size: 2.125rem;
    line-height: 1.06;
    margin-bottom: 1.125rem;
    text-transform: uppercase;

    @include break-lg {
      font-size: 3rem;
      line-height: 1.04;     
    }
  }
  h3{
    color: var(--wipfli-core-black);   
    font-family: var(--title-font-family);
    font-size: 1.5rem;
    line-height: 1.08;
    margin-bottom: 1.125rem;
    text-transform: uppercase;

    @include break-lg {
      font-size: 2.125rem;
      line-height: 1.06;     
    }
  }

  img {
    margin-top: toRem(9);

    &.full-width {
      width: 100%;
    }

    @include break-md {
      margin-top: toRem(38);
    }
  }
  ul {
    li{
      color: var(--wipfli-core-black);
      margin-bottom: toRem(8);
      &,*{
        font-size: toRem(14);
        line-height: 1.57;
        @include break-md{
          font-size: toRem(18);
          line-height: 1.44;
        }
      }
      @include break-md{
        margin-bottom: toRem(12);
      }
    }
  }
}