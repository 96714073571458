@import "../../scss/common";

.cta {
  background-color: #0050ff;
  width: 100%;
  &-container {
    width: 100%;
    max-width: 1110px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 63px 28px 60px 27px;
    @include break-md {
      flex-direction: column;
      padding: 63px 28px 60px 27px;
    }
    @include break-lg {
      flex-direction: row;
      justify-content: space-between;
      padding: 83px 20px;
    }
    @include break-xl{
      padding-left: 0;
      padding-right: 0;
    }
    &__right-align {
      .cta-container {
        align-items: flex-start;
      }
      .cta__left-align-title {
        text-align: center;
        line-height: 1.04;
        @include break-lg {
          text-align: left;
        }
      }
      .cta__right-align-btn-one {
        width: fit-content;
      }
      .cta__right-align {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        @include break-lg {
          display: block;
          width: 40%;
        }
      }
    }
  }
  &__left-align {
    width: 100%;
    @include break-lg {
      width: 50%;
    }
    &-title {
      color: var(--wipfli-white);
      font-size: 46px;
      font-weight: normal;
      letter-spacing: normal;
      line-height: 1.04;
      margin-bottom: 25px;
      text-align: center;
      text-transform: uppercase;
      @include break-md {
        font-size: 70px;
        line-height: 1;
      }
      @include break-lg {
        font-size: 70px;
        line-height: 1;
        margin: 0;
        text-align: left;
      }
    }
  }
  &__right-align {
    width: auto;
    @include break-lg {
      width: 40%;
    }
    &-description {
      color: var(--wipfli-white);
      font-size: 14px;
      line-height: 1.57;
      margin-bottom: 15px;
      text-align: center;
      @include break-lg {
        font-size: 18px;
        line-height: 1.44;
        text-align: left;
        margin-bottom: 27px;
      }
    }
    &-btn {
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-items: center;
      @include break-lg {
        flex-direction: row;
        gap: 33px;
      }
    }
  }
  &.cta__right-align{
    width: 100%;
    .cta-container{
      align-items: flex-start;
    }
    .cta__left-align-title {
      text-align: center;
      line-height: 1.04;
      @include break-lg {
        text-align: left;
      }
    }
      .cta__right-align-btn{
        width: fit-content;
        float:right;
        align-items: center;
      }
      .cta__right-align {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        @include break-lg {
          display: block;
          width: 40%;
        }
      }
    }
  }
