@import '../../scss/common';

.hero-page {
    padding: 5% 10%;
    background-color: #f2f3f3;

    @media (min-width: 662px) and (max-width: 1024px) {
        padding-inline: 5%;
        padding: 5%;
    }

    @media (max-width: 661px) {
        padding-inline: 28px;
        padding: 28px;
    }

    &_wrapper {
        display: flex;
        overflow: hidden;
        flex-direction: row;
        gap: 10px;

        @media (max-width: 661px) {
            flex-direction: column;
            gap: 40px;
        }

        &_info {
            text-align: left;
            padding: 0;
            margin-right: 100px;

            @media (min-width: 662px) and (max-width: 1024px) {
                margin-right: 0;
            }

            @media (max-width: 661px) {
                margin-right: 0;
            }

            &_eyebrow {
                color: var(--wipfli-blue);
                text-transform: uppercase;
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 30px;
                font-family: inherit;
                font-weight: 600;

                a {
                    text-decoration: none !important;
                }

                @media (min-width: 662px) and (max-width: 991px) {
                    font-size: 15px;
                    line-height: 15px;
                }

                @media (max-width: 661px) {
                    font-size: 14px;
                    line-height: 14px;
                    margin-bottom: 25px;
                }
            }

            &_title {
                font-size: 4rem;
                text-transform: uppercase;
                font-family: Anton, sans-serif;
                line-height: 64px;
                padding: 0;
                margin: 0;
                color: var(--wipfli-black);

                @media (min-width: 662px) and (max-width: 991px) {
                    font-size: 48px;
                    line-height: 48px;
                }

                @media (max-width: 661px) {
                    font-size: 34px;
                    line-height: 34px;
                }
            }

            &_sub-title {
                color: var(--wipfli-blue);
                text-transform: uppercase;
                font-size: 16px;
                line-height: 24px;
                margin: 30px 0px 20px;
                font-family: inherit;
                font-weight: 700;

                @media (min-width: 662px) and (max-width: 991px) {
                    font-size: 15px;
                    line-height: 15px;
                    margin: 20px 0 20px;
                }

                @media (max-width: 661px) {
                    font-size: 14px;
                    line-height: 14px;
                    margin: 20px 0 15px;
                }
            }

            &_descp {
                color: var(--wipfli-black);
                font-size: 16px;
                line-height: 24px;
                font-family: 'Lora';
                font-weight: 500;

                @media (min-width: 662px) and (max-width: 991px) {
                    font-size: 15px;
                    line-height: 18px;
                }

                @media (max-width: 661px) {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }

        &_img {
            max-width: 660px;
            max-height: 450px;
            object-fit: cover;

            &_block {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}