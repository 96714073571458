@import '../../scss/common';

.swiper-creative .swiper-slide {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  overflow: hidden;
  transition-property: transform, opacity, height;
}

.step-list {
  --step-list-background-color: var(--wipfli-core-black);
  --step-list-color: var(--wipfli-white);

  //************************************************
  &__title {
    background-color: var(--step-list-background-color);
    height: 100%;
    padding-top: 16px;

    &.light {
      --step-list-background-color: var(--wipfli-white);
      --step-list-color: var(--wipfli-core-black);
    }

    h2 {
      // mobile first
      display: block;
      color: var(--wipfli-blue);
      padding: 0 11px;
      margin: 0;
    }
  }

  //************************************************
  // desktop overrides
  @include break-lg {
    &__title h2 {
      max-width: 1110px;
      margin: 0 auto;
      padding: 0;
    }
  }

  @include break-xl {
    &__title h2 {
      // padding: 0;
      max-width: 1110px;
      margin-inline: auto;
    }
  }
  &.step-list__right-align {
    .step-list__inner-contain-right {
      order: 1;
    }
    .step-list__inner-contain-left {
      order: 2;
    }
  }
  &__video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;

    video {
      object-fit: cover;
    }
    &-modal {
      background-color: rgba($color: #63666a, $alpha: 0.8);
      position: relative;
      max-width: toRem(494);
      margin: 0 auto;
      transform: scale(0);
      transition: transform 0.4s ease-in-out;
      width: 100%;

      @include break-md {
        max-width: toRem(624);
      }

      @include break-xl {
        margin-top: toRem(20);
        max-width: toRem(1110);
      }

      &-closebtn {
        background-color: transparent;
        border: none;
        position: absolute;
        height: toRem(20);
        width: toRem(21);
        right: 0;
        top: toRem(-30);
        z-index: 1;

        &::after,
        &::before {
          position: absolute;
          left: toRem(8);
          content: ' ';
          height: toRem(16);
          width: toRem(2);
          background-color: var(--wipfli-white);
          top: 0;
        }

        &::after {
          transform: rotate(-45deg);
        }

        &::before {
          transform: rotate(45deg);
        }

        &:focus {
          outline: none;
          border: toRem(2) dotted var(--wipfli-white);
        }
      }

      &-contain {
        position: relative;
        padding-top: 56.25%;
        height: 0;
      }

      &-container {
        align-items: center;
        background-color: rgba($color: #000000, $alpha: 0.6);
        display: flex;
        justify-content: center;
        inset: 0;
        padding: 0 toRem(20);
        position: fixed;
        pointer-events: none;
        opacity: 0;
        visibility: hidden;

        transition: all 0.2s ease-in-out;

        &.active {
          opacity: 1;
          visibility: visible;
          pointer-events: all;
          z-index: 99;

          .step-list__video-modal {
            transform: scale(1);
          }
        }
      }
    }
  }
}

.step-list-gallery {
  --title-line-height-desktop: 50px;
  --title-line-height-mobile: 36px;
  --top-spacing-mobile: 58px;
  --top-spacing-desktop: 90px;

  //************************************************
  // mobile first

  background-color: var(--step-list-background-color);
  color: var(--step-list-color);
  position: relative;
  padding: var(--top-spacing-mobile) 11px 26px 11px;

  &.light {
    --step-list-background-color: var(--wipfli-white);
    --step-list-color: var(--wipfli-core-black);
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: calc(var(--top-spacing-mobile) + (0.5 * var(--title-line-height-mobile)));
    left: 10px;
    bottom: 0;
    height: calc(100%);
    border-left: 3px solid var(--wipfli-blue);
    transform: translateX(-50%);
  }

  [class^='step-list-'] + &::before,
  & + &::before {
    top: 0;
  }

  &::after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    background-color: var(--step-list-background-color);
    border: 3px solid var(--wipfli-blue);
    position: absolute;
    top: calc(var(--top-spacing-mobile) + (0.5 * var(--title-line-height-mobile)));
    left: 10px;
    transform: translate(-50%, -50%);
  }

  &__content {
    padding-inline: 14px;
  }

  h3 {
    color: var(--step-list-color);
    font-size: 34px;
    line-height: var(--title-line-height-mobile);
    text-transform: uppercase;
    margin-bottom: 16px;
    position: relative;
  }

  p {
    margin-bottom: 24px;
  }

  &__columns {
    display: grid;
    gap: 1em;
  }

  .swiper {
    padding-top: 60px;
    padding-bottom: 80px;
  }

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {
    img,
    .lottieImage {
      min-height: 150px;
      aspect-ratio: 1.867;
      object-fit: cover;
    }
  }

  .swiper-scrollbar.swiper-scrollbar-horizontal {
    background: transparent;
    width: 75%;
    left: 0;
    right: 0;
    margin-inline: auto;
    top: auto;
    bottom: 38px;
    height: 20px;

    &::after {
      height: 2px;
      background: var(--wipfli-blue);
      content: '';
      opacity: 0.71;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 5;
      transform: translate(0, 9px);
      border-radius: 2px;
    }
  }

  .swiper-scrollbar-drag {
    background: var(--wipfli-blue);
  }

  .swiper-pagination {
    bottom: 5px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: auto;
    bottom: 38px;
    margin-top: 0;
    border: 2px solid var(--step-list-color);
    border-radius: 50%;
    height: 25px;
    width: 25px;
    color: var(--wipfli-white);

    &::after {
      content: '';
      height: 12px;
      width: 7px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      background: var(--step-list-color);
      -webkit-mask: url('../../../public/local/Home/Project/demo-site/demo-site/cta-arrow.png')
        center/contain;
      mask: url('../../../public/local/Home/Project/demo-site/demo-site/cta-arrow.png')
        center/contain;
    }
  }

  .swiper-button-prev {
    left: 0;

    &::after {
      content: '';
      transform: translate(-50%, -50%) rotate(-180deg);
    }
  }

  .swiper-button-next {
    right: 0;
  }

  &.light {
    .swiper-pagination {
      color: var(--wipfli-blue);
    }

    .swiper-button-prev,
    .swiper-button-next {
      border-color: var(--wipfli-blue);

      &:after {
        background: var(--wipfli-blue);
      }
    }
  }

  .swiper-button-prev:not(.swiper-button-disabled),
  .swiper-button-next:not(.swiper-button-disabled) {
    &:hover {
      background-color: var(--wipfli-blue);
      border-color: var(--wipfli-blue);

      &:after {
        background: var(--wipfli-white);
      }
    }
  }

  .swiper-button-prev.swiper-button-disabled,
  .swiper-button-next.swiper-button-disabled {
    opacity: 0.6;
  }

  //************************************************
  // tablet overrides
  @include break-md {
    &__columns {
      flex-direction: row;
    }
  }

  //************************************************
  // desktop overrides
  @include break-lg {
    padding-top: var(--top-spacing-desktop);
    padding-bottom: var(--top-spacing-desktop);

    &::before {
      top: calc(var(--top-spacing-desktop) + (0.5 * var(--title-line-height-desktop)));
      left: 20px;
    }

    &::after {
      left: 20px;
      top: calc(var(--top-spacing-desktop) + (0.5 * var(--title-line-height-desktop)));
      // transform: translateX(-50%);
    }

    [class^='step-list-'] + &::before {
      height: 100%;
      top: 0;
    }

    &__content {
      max-width: 1110px;
      margin: 0 auto;
      padding: 0px 30px;
    }

    &__description {
      max-width: 50%;
    }

    h3 {
      font-size: 48px;
      line-height: var(--title-line-height-desktop);
      text-transform: uppercase;
      margin-bottom: 23px;
    }

    &__columns {
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
    }

    .swiper-button-prev,
    .swiper-button-next {
      top: auto;
      bottom: 32px;
      height: 35px;
      width: 35px;

      &::after {
        content: '';
        height: 16.8px;
        width: 9.8px;
      }
    }

    .swiper-button-prev {
      left: 6.25%;
    }

    .swiper-button-next {
      right: 6.25%;
    }
  }

  @include break-xl {
    &::before {
      // top: 90px;
      // top: calc(var(--top-spacing-desktop) + (0.5 * var(--title-line-height-desktop)));
      left: calc(0.5 * (100% - 1110px));
      height: calc(100% - 90px);
      border-left: 2.5px solid var(--wipfli-blue);
      // transform: translateX(-50%);
    }

    &::after {
      width: 17px;
      height: 17px;
      -moz-border-radius: 8.5px;
      -webkit-border-radius: 8.5px;
      border-radius: 8.5px;
      border: 2.5px solid var(--wipfli-blue);
      // top: calc(var(--top-spacing-desktop) + (0.5 * var(--title-line-height-desktop)));
      left: calc(0.5 * (100% - 1110px));
      // transform: translateX(-50%);
    }
  }
}

// TWO COLUMN VARIATION
.step-list-two-column {
  --title-line-height-desktop: 50px;
  --title-line-height-mobile: 36px;
  --top-spacing-mobile: 58px;
  --top-spacing-desktop: 90px;

  //************************************************
  // mobile first

  background-color: var(--step-list-background-color);
  color: var(--step-list-color);
  position: relative;
  padding: var(--top-spacing-mobile) 11px;

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: calc(var(--top-spacing-mobile) + (0.5 * var(--title-line-height-mobile)));
    left: 10px;
    bottom: 0;
    height: calc(100%);
    border-left: 3px solid var(--wipfli-blue);
    transform: translateX(-50%);
  }

  [class^='step-list-'] + &::before {
    top: 0;
  }

  &::after {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    background-color: var(--step-list-background-color);
    border: 3px solid var(--wipfli-blue);
    position: absolute;
    top: calc(var(--top-spacing-mobile) + (0.5 * var(--title-line-height-mobile)));
    left: 10px;
    transform: translate(-50%, -50%);
  }

  &.light {
    --step-list-background-color: var(--wipfli-white);
    --step-list-color: var(--wipfli-black);
  }

  &__content {
    padding-inline: 14px;
  }

  h3 {
    color: var(--step-list-color);
    font-size: 34px;
    line-height: var(--title-line-height-mobile);
    text-transform: uppercase;
    margin-bottom: 15px;
    position: relative;
  }

  &__columns {
    display: grid;
    gap: 1em;
  }

  p {
    margin-bottom: 0;
    line-height: 1.57;
  }

  .list-title {
    font-weight: 600;
    font-family: var(--primary-font-family);
    color: var(--wipfli-white);
    margin-top: 2rem;

    @include break-md {
      margin-top: 4rem;
    }
  }

  ul {
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    padding-left: 1rem;
    font-size: 12px;
    line-height: 1.5;

    li {
      font-weight: 500;
      color: var(--wipfli-white);
      padding-right: 12px;

      &::marker {
        color: var(--wipfli-white);
      }
    }
  }

  &.light {
    ul,
    li::marker,
    li,
    .list-title {
      color: var(--wipfli-blue);
    }
  }

  img,
  .lottieImage {
    height: auto;
  }

  //************************************************
  // tablet overrides
  @include break-md {
    &__columns {
      flex-direction: row;
    }
  }

  //************************************************
  // desktop overrides
  @include break-lg {
    padding-top: var(--top-spacing-desktop);
    padding-bottom: var(--top-spacing-desktop);

    &::before {
      top: calc(var(--top-spacing-desktop) + (0.5 * var(--title-line-height-desktop)));
      left: 20px;
    }

    &::after {
      left: 20px;
      top: calc(var(--top-spacing-desktop) + (0.5 * var(--title-line-height-desktop)));
      transform: translate(-50%, -50%);
    }

    [class^='step-list-'] + &::before {
      height: 100%;
      top: 0;
    }

    &__content {
      max-width: 1110px;
      margin: 0 auto;
      padding: 0px 30px;
    }

    h3 {
      font-size: 48px;
      line-height: var(--title-line-height-desktop);
      text-transform: uppercase;
      margin-bottom: 23px;
    }

    &__columns {
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
    }

    p {
      line-height: 1.44;
    }

    ul {
      columns: initial;
      -webkit-columns: initial;
      -moz-columns: initial;
      font-size: 16px;

      li {
        font-size: 16px;
        line-height: 1.5;
        padding-right: 0;
      }
    }
  }

  @include break-xl {
    &::before {
      left: calc(0.5 * (100% - 1110px));
      height: calc(100% - 90px);
      border-left: 2.5px solid var(--wipfli-blue);
    }

    &::after {
      width: 17px;
      height: 17px;
      -moz-border-radius: 8.5px;
      -webkit-border-radius: 8.5px;
      border-radius: 8.5px;
      border: 2.5px solid var(--wipfli-blue);
      left: calc(0.5 * (100% - 1110px));
      // transform: translateX(-50%);
    }
  }
}
