/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin, padding */
button,
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  padding: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
/* Add role="list" to ul/ol used in component markup to remove bullet styling for default lists*/
//ul,
ol,
ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol,
ul {
  padding-left: 1.5rem;
}

ul li::marker {
  color: var(--wipfli-blue);
}

/* Set core root defaults */
html,
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  color: var(--wipfli-core-black);
  min-height: 100vh;
  text-rendering: optimizespeed;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

button {
  letter-spacing: unset;
}

fieldset {
  border: none;
  padding: 0;
}

/* for print */
@media print {
  * {
    -webkit-print-color-adjust: exact;

    /* Chrome, Safari, Edge */
    color-adjust: exact;

    /* Firefox */
  }
}
