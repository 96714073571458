@import '../../scss/common';

.global-search-container {
  display: flex;
  flex-direction: column;
  gap: 0px;
  width: 50%;
  position: relative;
  justify-content: flex-start;

  .global-search_wrapper {
    margin-right: 10px;
    display: flex;
    justify-content: end;
    align-items: center;
    position: relative;

    &_field {
      width: 0px;
      height: 40px;
      background: transparent;
      color: #fff;
      padding-left: 15px;
      border: none;
      display: none;
    }

    .active {
      width: 100%;
      border: 2px solid rgb(250, 250, 250, 0.8);
      transition: all 0.4s ease-in-out;
      outline: none;
      display: inline-block;
    }

    svg {
      position: absolute;
      right: 10px;
      cursor: pointer;
    }

    @media (max-width: 661px) {
      display: none;
    }
  }

  &_body {
    background-color: rgb(250, 250, 250, 0.8);
    margin-top: 40px;
    position: absolute;
    visibility: hidden;
    width: 97.4%;

    &_active {
      visibility: visible;
    }

    @media (max-width: 661px) {
      width: 100%;
      margin-top: 0;
      background-color: #FFF;
    }
  }

  &_list {
    list-style-type: none;
    margin: 0;
    padding: 0px;

    &_item {
      font-size: 15px;
      line-height: normal;
      padding-left: 15px;
      color: #0050ff7a;
      padding: 10px 15px 10px;
      font-weight: 500;
      display: block;
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 2px solid var(--wipfli-core-black);
      }

      b {
        color: #0050ff;
      }

      &:hover {
        background-color: #d5dde3;
      }

      &_link {
        text-decoration: none !important;
        text-transform: capitalize;
        color: #0050ff7a !important;
        display: block;

        &:hover {
          text-decoration: none !important;
          border-bottom: none !important;
        }
      }
    }

    @media (max-width: 661px) {
      padding: 0 30px;
    }
  }

}

.global-search-container-mobile {
  display: none;
  z-index: 1;

  @media (max-width: 661px) {
    display: block;
  }

  &_body {
    display: none;
    background-color: #FFF;

    &_active {
      display: block;
      position: fixed;
      left: 0;
      width: 100%;
      height: 100%;
      top: 0;
    }

    &_logo {
      gap: 70px;
      padding: 0px 30px;
      align-items: center;
      display: flex;
    }
  }

  .global-search_wrapper {
    display: flex;
    justify-content: end;
    align-items: center;
    position: relative;

    &_mobile {
      margin: 60px 30px 0;

      svg {
        fill: #0050ff;
      }
    }

    &_field {
      height: 40px;
      background: transparent;
      padding-left: 15px;
      border: none;
      display: block;
      border: 2px solid #0050ff;
      color: #0050ff;
      font-weight: 500;
      padding-left: 15px;
      width: 100%;

      &:focus {
        border-color: 2px solid #0050ff;
        outline: none !important;
      }
    }

    .active {
      width: 100%;
      border: 2px solid rgb(250, 250, 250, 0.8);
      transition: all 0.4s ease-in-out;
      outline: none;
      display: inline-block;
    }

    svg {
      position: absolute;
      right: 10px;
      cursor: pointer;
    }
  }
}