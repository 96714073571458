@import '../../scss/common';


.article-page-title {
  color: var(--wipfli-blue);
  font-size: toRem(34);
  font-family: var(--title-font-family);
  line-height: 1.06;
  margin-bottom: toRem(18);
  text-transform: uppercase;

  @include break-md {
    font-size: toRem(48);
    line-height: 1.04;
    margin-bottom: toRem(30);
  }
}

.article-page-summary {

  &,
  p {
    color: var(--wipfli-core-black);
    font-size: toRem(14);
    line-height: 1.57;

    @include break-md {
      font-size: toRem(18);
      line-height: 1.44;
    }
  }

  h2 {
    color: var(--wipfli-blue);
    font-family: var(--title-font-family);
    font-size: 2.125rem;
    line-height: 1.06;
    margin-bottom: 1.125rem;
    text-transform: uppercase;

    @include break-lg {
      font-size: 3rem;
      line-height: 1.04;
    }
  }

  h3 {
    color: var(--wipfli-core-black);
    font-family: var(--title-font-family);
    font-size: 1.5rem;
    line-height: 1.08;
    margin-bottom: 1.125rem;
    text-transform: uppercase;

    @include break-lg {
      font-size: 2.125rem;
      line-height: 1.06;
    }
  }

  p {
    margin-bottom: 0;

    &:not(:first-child) {
      margin-top: 15px;

      @include break-md {
        margin-top: 30px;
      }

    }
  }
}